import React from 'react';
import './App.css';
import Router from './routes';
import useAuthenticate from './hooks/useAuthenticate';
import LoginDialog from './components/loginDialog';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: '#01413a',
    },
  },
});

function App() {
  const { isLogged, login } = useAuthenticate();
  return (
    <div className="App">
      <CssBaseline/>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Router isLogged={isLogged} />
          <LoginDialog open={!isLogged} doLogin={login} />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
