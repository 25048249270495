import Environment from '../entities/environment';
import ApiNotOk from '../entities/errors/apiNotOk';
import ApiError from '../entities/errors/apiError';
import { getTokenSync, emmitLogout } from '../hooks/useAuthenticate';

export default class AbstractApiService {
  static base = `${Environment.get()}`;

  static async fetch(url, params = {}, isFormData = false) {
    if (!isFormData) {
      params.headers = {
        ...params.headers,
        'Content-Type': 'application/json',
      };
    }

    const response = await fetch(`${this.base}${url}`, {
      ...params,
      headers: {
        ...params.headers,
        'x-requested-with': 'BACKOFFICE',
        'x-access-token': getTokenSync(),
      },
    });

    if (!response.ok) {
      ApiNotOk.throwError();
    }

    const result = await response.json();

    if (result.error) {
      if (result.message.includes('Token Wrong')) {
        emmitLogout();
      }
      ApiError.throwError(result.message);
    }

    return result.data;
  }
}
