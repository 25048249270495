export const enUserRoles = {
  ADMIN: 'Administrador',
  MAYOR: 'Prefeito',
  SECRETARY: 'Secretário',
  SECTOR_MANAGER: 'Chefe de setor',
  SECTOR_MEMBER: 'Funcionário da prefeitura',
  SERVICE_PROVIDER: 'Prestador de Serviços',
  PUBLIC_PERSON: 'Pessoa Pública',
  USER: 'Cidadão',
  SERVICES_REQUESTER: 'Funcionário da Ouvidoria',
};

export const enUserRolesId = {
  ADMIN: 'ADMIN',
  MAYOR: 'MAYOR',
  SECRETARY: 'SECRETARY',
  SECTOR_MANAGER: 'SECTOR_MANAGER',
  SECTOR_MEMBER: 'SECTOR_MEMBER',
  SERVICE_PROVIDER: 'SERVICE_PROVIDER',
  PUBLIC_PERSON: 'PUBLIC_PERSON',
  USER: 'USER',
  SERVICES_REQUESTER: 'SERVICES_REQUESTER',
};
