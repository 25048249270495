import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from 'react-data-table-component';
import { Box, Typography } from '@material-ui/core';
import { ExclusionDialog } from './exclusionDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
}));

export default ({
  title,
  columns = [],
  actions = [],
  fetch,
  onRemove,
  removeId,
  removeService,
}) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [orderBy, setOrderBy] = useState();
  const [filter, setFilter] = useState();

  const [open, setOpen] = useState(false);

  const refresh = useCallback(() => {
    if (fetch) {
      fetch(filter, Math.max(page-1, 0), countPerPage, orderBy).then((result) => {
        setData(result.result.list);
        setTotalRows(result.result.countQuery);
      });
    }
  }, [fetch, filter, page, countPerPage, orderBy]);

  useEffect(() => {
    if (removeId) {
      setOpen(true);
    }
  }, [removeId]);

  useEffect(() => {
    if (!removeId && !open) {
      refresh();
    }
  }, [open, refresh, removeId]);

  const onChangeRowsPerPage = useCallback((evt) => {
    setCountPerPage(evt);
    setPage(1);
    setOrderBy(null);
  }, []);

  const onChangePage = useCallback((evt, evt2) => {
    setPage(evt);
  }, []);

  const onSort = useCallback((column, direction) => {
    setOrderBy({ [column.selector]: direction });
  }, []);

  const onChangeFilter = useCallback((evt) => {
    setFilter(evt.target.value);
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <Grid container className={classes.root}>
      <Grid xs={12} justifyContent="flex-end" container>
        <Box display="flex" alignItems="flex-end" width="260px" justifyContent="space-between" margin="0 50px 20px 0">
          <Typography variant="button">Filtrar:</Typography>
          <TextField onChange={onChangeFilter} placeholder="" />
        </Box>
      </Grid>
      <Grid item md={12} xs={12}>
        <DataTable
          customStyles={{
            header: {
              style: {
                display: 'none'
              }
            },
            headRow: {
              style: {
                backgroundColor: '#f5f6fa',
                height: '60px',
                border: 'none',
              },
            },
            headCells: {
              style: {
                fontWeight: 'bold',
                fontSize: '1.1em',
                textTransform: 'uppercase',
                color: '#57575a'
              },
            },
            rows: {
              style: {},
            },
            cells: {
              style: {
                textOverflow: "ellipsis",
              }
            }
          }}
          title={title}
          pagination
          highlightOnHover
          noDataComponent={
            <>
              <Typography variant={'h6'}>
                Ainda não existem registros para serem listados aqui.
              </Typography>
            </>
          }
          columns={columns}
          paginationPerPage={countPerPage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          paginationComponentOptions={{
            rowsPerPageText: 'Registros por página',
            rangeSeparatorText: 'de',
            selectAllRowsItemText: 'Todos',
          }}
          paginationTotalRows={totalRows}
          onSort={onSort}
          data={data}
          responsive
          paginationServer
          striped
        />
        <ExclusionDialog
          open={open}
          setOpen={setOpen}
          removeId={removeId}
          removeService={removeService}
          onRemoved={() => {
            onRemove();
          }}
        />
      </Grid>
    </Grid>
  );
};
