import Grid from '@material-ui/core/Grid';
import { Card, CardActionArea, CardActions, CardMedia } from '@material-ui/core';
import React, { useState } from 'react';
import { PhotoDialog } from './photoDialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

export function PhotoGrid(props) {
  const { attachments, removeFunction } = props;

  const [dialog, setDialog] = useState({
    open: false,
    image: null,
  });

  if (!attachments || !attachments.length) {
    return null;
  }

  return (
    <>
      <br />
      <Grid container justifyContent={'flex-start'} spacing={2}>
        {attachments.map((photo, index) => (
          <Grid item md={2} xs={4} key={index}>
            <Card elevation={3}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={photo}
                  onClick={() => {
                    setDialog({
                      open: true,
                      image: photo,
                    });
                  }}
                />
              </CardActionArea>
              {removeFunction && (
                <CardActions>
                  <Button size="small" color="primary" onClick={() => removeFunction(index)}>
                    Remover
                  </Button>
                </CardActions>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>

      <PhotoDialog
        open={dialog.open}
        image={dialog.image}
        onClose={() => {
          setDialog({
            open: false,
            image: null,
          });
        }}
      />
    </>
  );
}
