import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useNavigate, useParams } from '@reach/router';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Icon,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select, Snackbar,
  Typography
} from '@material-ui/core';
import ServiceService from '../../services/models/service';
import { snakeCase } from 'snake-case';
import { iconsArray } from '../../components/iconsArray';
import CompanyService from '../../services/models/company';
import RichTextEditor from '../../components/textEditor';
import UploadImages from '../../components/imageField';
import { Alert } from '../../components/toast';
import ServiceRequest from '../../services/models/serviceRequest';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    backgroundColor: theme.palette.background.paper
  },
  card: {
    padding: theme.spacing(2)
  },
  formWrapper: {},
  buttons: {
    padding: '5px 40px'
  },
  spacer: {
    marginBottom: '30px'
  }
}));

export default () => {
  const classes = useStyles();
  const { handleSubmit, register, errors, formState, setValue, control, watch } = useForm({
    defaultValues: {
      description: ''
    }
  });
  const navigate = useNavigate();
  const params = useParams();

  // const description = watch('description');

  const [description, setDescription] = useState('');

  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'info'
  });

  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (!companies.length) {
        const companiesReq = await CompanyService.list(null, 0, 1000);
        setCompanies(companiesReq.result.list);
      }
    }

    fetchData();
  }, []);

  useEffect(() =>{
    console.log(description)
    setValue('description', description);
  }, [description])

  const onSubmit = useCallback(
    async ({ service, ...rest }) => {
      const div = document.createElement('div');
      div.innerHTML = description;
      const cleanText = div.textContent || div.innerText || '';

      console.log(rest, description)

      if(!service){
        setToast({
          open: true,
          severity: 'error',
          message: 'Selecione o tipo de serviço da demanda!'
        });
        return;
      }
      if (cleanText.length < 30) {
        setToast({
          open: true,
          severity: 'error',
          message: 'Descreva a demanda com pelo menos 30 caracteres.'
        });
        return;
      }

      const formData = new FormData();
      formData.append('description', description);
      formData.append('service', service);
      formData.append('location', JSON.stringify({
        address: "Viana",
        lat: -20.3556181,
        lng: -40.4202111
      }));

      if (historyImages && historyImages.currentFiles) {
        historyImages.currentFiles.forEach((file) => {
          formData.append('attachments', file);
        });
      }

      ServiceRequest.save(formData)
        .then((result) => {
          setToast({
            open: true,
            severity: 'success',
            message: 'Demanda criada com sucesso!',
          });
          // navigate('/request');
        })
        .catch((err) => {
          setToast({
            open: true,
            severity: 'error',
            message: 'Erro ao criar demanda.'
          });
        });
    },
    [navigate, params.id, description]
  );

  useEffect(() => {
    if (params.id) {
      ServiceService.findById(params.id)
        .then((user) => {
          for (let key in user) {
            setValue(key, user[key]);
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  }, [params.id, setValue]);

  const [services, setServices] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (!services.length) {
        const servicesReq = await ServiceService.list(null, 0, 1000);
        setServices(servicesReq.result.list);
      }
    }

    fetchData();
  }, []);


  const [historyImages, setHistoryImages] = useState({
    currentFiles: undefined,
    previewImages: undefined
  });

  return (
    <Box p='20px'>
      <Typography color='primary' variant={'h4'} component='h4'>
        Serviços
      </Typography>
      <Box m='10px' />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Box display='flex' alignContent='center' justifyContent='center'>
            <Typography color='primary' variant='h5'>
              Nova Demanda
            </Typography>
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box
            component='form'
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
            className={classes.formWrapper}
            padding='40px'
          >
            <Grid item md={12} xs={12}>
              <InputLabel id='secretariat-select-label'>Tipo de Serviço</InputLabel>
              <Controller
                as={
                  <Select
                    fullWidth={true}
                    labelId='secretariat-select-label'
                    label='Secretaria'
                    name={'secretariat'}
                    error={errors.secretary}
                    InputLabelProps={{ shrink: !!params.id }}
                  >
                    {services.map((sec) => (
                      <MenuItem key={sec._id} value={sec._id}>
                        <ListItemIcon>
                          <Icon fontSize={'small'}>{snakeCase(sec.icon)}</Icon>
                        </ListItemIcon>
                        <ListItemText>{sec.name}</ListItemText>
                      </MenuItem>
                    ))}
                  </Select>
                }
                control={control}
                defaultValue={''}
                rules={{
                  required: true
                }}
                name={'service'}
              />
            </Grid>

            <Grid item md={12} xs={12} style={{
              paddingTop: 20
            }}>
              <InputLabel id='secretariat-select-label'>Descrição</InputLabel>
              <RichTextEditor value={description} onChange={(value) => setDescription(value)} />
            </Grid>

            <UploadImages
              images={historyImages}
              onChange={(files) => {
                setHistoryImages(files);
              }}
            />

            <Box width='100%' display='flex' justifyContent='space-between' alignItems='center'>
              <Button
                component={Link}
                to='/request'
                className={classes.buttons}
                size='small'
                variant='contained'
              >
                Cancelar
              </Button>
              <Button
                type='submit'
                className={classes.buttons}
                size='small'
                variant='contained'
                color='primary'
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Snackbar
        open={toast.open}
        autoHideDuration={1500}
        onClose={() => {
          setToast({
            ...toast,
            open: false
          });
        }}
      >
        <Alert
          onClose={() => {
            setToast({
              ...toast,
              open: false
            });
          }}
          severity={toast.severity}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
