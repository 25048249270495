import React, { useState } from 'react';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';

import PersonIcon from '@material-ui/icons/Person';
import AuthenticateService from '../services/authenticate';
import { Alert } from './toast';
import { FormControl, Input, InputAdornment, InputLabel, Snackbar } from '@material-ui/core';
import ReactInputMask from 'react-input-mask';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'lavender',
    minHeight: '100vh',
  },
  box: {
    background: 'white',
    border: '2px solid #ddd',
    borderRadius: 11,
    padding: 20,
  },
  formChild: {
    backgroundColor: "#fff",
    padding: theme.spacing(0, 1, 0, 1),
    height: "36px",
  },

  item: {
    marginTop: `${theme.spacing(2)}px`,
  },

  iconSecondary: {
    color: theme.palette.text.secondary,
  },

  inputLabel: {
    color: "#fff",
    fontSize: "1.1rem",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({ open, doLogin }) => {
  const classes = useStyles();
  const { handleSubmit, register, errors, control } = useForm({
    defaultValues: {
      document: "",
      password: "",
    }
  });

  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'info',
    handleClose: () => {
      console.log('do nothing');
    },
  });

  const onSubmit = async (data) => {
    try {
      debugger;
      const response = await AuthenticateService.login(data);
      await doLogin(response.token);
    } catch (err) {
      setToast({
        open: true,
        severity: 'error',
        message: err.message,
        handleClose: () => {
          setToast({
            open: false,
          });
        },
      });
    }
  };

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <Grid className={classes.root} container alignItems="center" justify="center">
        <Grid className={classes.box} item xs={6} md={3}>
          <h2 style={{ margin: 0, passing: 2, textAlign: 'center' }}>Login</h2>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12} md={12}>
              <FormControl>
                <InputLabel className={classes.inputLabel} htmlFor="document">
                  CPF
                </InputLabel>
                <Controller
                  control={control}
                  mask="999.999.999-99"
                  name="document"
                  render={(field) => (
                    <ReactInputMask mask="999.999.999-99" {...field}>
                      {(props) => (
                        <Input
                          fullWidth
                          className={classes.formChild}
                          placeholder="CPF"
                          variant="filled"
                          type="tel"
                          startAdornment={
                            <InputAdornment position="start">
                              <PersonIcon className={classes.iconSecondary} />
                            </InputAdornment>
                          }
                          {...props}
                        />
                      )}
                    </ReactInputMask>
                  )}
                />
              </FormControl>
            </Grid>

            <br />

            <Grid item xs={12} md={12}>
              <TextField
                fullWidth={true}
                label="Senha"
                name="password"
                helperText={errors.password ? 'Dado inválido' : null}
                error={errors.password}
                type="password"
                inputRef={register({ required: true })}
              />
            </Grid>

            <br />

            <Grid item xs={12} md={12} style={{ textAlign: 'right', padding: '5px 0px' }}>
              <Button type="submit" color="primary" variant="contained">
                Entrar
              </Button>
            </Grid>
          </form>
          <Snackbar open={toast.open} autoHideDuration={1500} onClose={toast.handleClose}>
            <Alert onClose={toast.handleClose} severity={toast.severity}>
              {toast.message}
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    </Dialog>
  );
};
