import { ConfirmationDialogRaw } from './confirmationDialog';
import { Snackbar } from '@material-ui/core';
import { Alert } from './toast';
import React, { useEffect, useRef, useState } from 'react';

export function ExclusionDialog(props) {
  const { open, setOpen, onRemoved, removeId, removeService } = props;

  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'info',
    handleClose: () => {},
  });

  return (
    <>
      <ConfirmationDialogRaw
        open={open}
        title={'Deseja realmente remover este registro?'}
        okText={'Sim, excluir'}
        cancelText={'Não, cancelar'}
        onConfirm={() => {
          const toastBase = {
            open: true,
            handleClose: () => {
              setToast({
                open: false,
              });
            },
          };
          removeService
            .remove(removeId)
            .then(() => {
              setToast({
                ...toastBase,
                severity: 'success',
                message: `Registro Removido com sucesso!`,
              });
            })
            .catch(() => {
              setToast({
                ...toastBase,
                severity: 'error',
                message: `Erro ao excluir registro!`,
              });
            });
          setOpen(false);
          if (onRemoved) {
            onRemoved();
          }
        }}
        onClose={() => {
          setOpen(false);
        }}
      />
      <Snackbar open={toast.open} autoHideDuration={1500} onClose={toast.handleClose}>
        <Alert onClose={toast.handleClose} severity={toast.severity}>
          {toast.message}
        </Alert>
      </Snackbar>
    </>
  );
}
