import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useNavigate, useParams } from '@reach/router';
import CompanyService from '../../services/models/company';
import { Card, InputLabel, MenuItem, Select, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '../../components/toast';
import ServiceService from '../../services/models/service';
import { Form } from '../../components/Form';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    padding: theme.spacing(2),
  },
}));

export default () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [services, setServices] = useState([]);
  const [initial, setInitial] = useState({});

  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'info',
    handleClose: () => {
      console.log('do nothing');
    },
  });

  const onSubmit = useCallback(
    async ({ passwordConfirm, ...data }) => {
      try {
        if (params.id) {
          data._id = params.id;
        }
        const result = await CompanyService.save(data);
        setToast({
          open: true,
          severity: 'success',
          message: `Empresa ${params.id ? 'editada' : 'criada'} com sucesso`,
          handleClose: () => {
            setToast({
              open: false,
            });
            navigate('/company');
          },
        });
      } catch (err) {
        alert(err.message);
      }
    },
    [navigate, params.id]
  );

  useEffect(() => {
    if (params.id) {
      CompanyService.findById(params.id)
        .then((company) => {
          setInitial(company)
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  }, [params.id]);

  useEffect(() => {
    async function fetchData() {
      if (!services.length) {
        const companiesReq = await ServiceService.list(null, 0, 1000);
        setServices(companiesReq.result.list);
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <Typography variant={'h5'}>Empresas</Typography>
      <Grid container className={classes.root}>
        <Grid item md={12} xs={12}>
          <Card elevation={2} className={classes.card}>
            <Form
              initialValues={initial}
              onSubmit={onSubmit}
              render={ ({values, handleSubmit, registerField, resetInitial}) => (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item md={2} xs={12}>
                      <TextField
                        fullWidth={true}
                        label="CNPJ"
                        {...registerField('document')}
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth={true}
                        label="Nome"
                        {...registerField('name')}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <InputLabel id="services-select-label">Serviços Prestados</InputLabel>
                      <Select
                        multiple={true}
                        fullWidth={true}
                        labelId="services-select-label"
                        label="Prestadores autorizados"
                        {...registerField('services', {multiple: true})}
                      >
                        {services.map((service, index) => (
                          <MenuItem
                            key={service._id}
                            value={service._id}
                          >{`[${service.code}] ${service.name}`}</MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Grid container spacing={2}>
                        <Grid item md={1} xs={12}>
                          <TextField
                            fullWidth={true}
                            label="CEP"
                            {...registerField('address.zipCode')}
                          />
                        </Grid>
                        <Grid item md={3} xs={12}>
                          <TextField
                            fullWidth={true}
                            label="Endereço"
                            {...registerField('address.addressLine1')}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <TextField
                            fullWidth={true}
                            label="Complemento"
                            {...registerField('address.addressLine2')}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <TextField
                            fullWidth={true}
                            label="Bairro"
                            {...registerField('address.neighborhood')}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <TextField
                            fullWidth={true}
                            label="Cidade"
                            {...registerField('address.city')}
                          />
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <TextField
                            fullWidth={true}
                            label="Estado"
                            {...registerField('address.state')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item md={12} xs={12} className={classes.buttons}>
                      <Link to="/company">
                        <Button
                          type="button"
                          variant="contained"
                          color="secondary"
                        >
                          Voltar
                        </Button>
                      </Link>
                      <Button type="submit" variant="contained">
                        Salvar
                      </Button>
                    </Grid>

                    <Snackbar open={toast.open} autoHideDuration={1500} onClose={toast.handleClose}>
                      <Alert onClose={toast.handleClose} severity={toast.severity}>
                        {toast.message}
                      </Alert>
                    </Snackbar>
                  </Grid>
                </form>
              )}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
