import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

export function UserDialog(props) {
  const { onClose, open, user, ...other } = props;

  return (
    <Dialog
      // sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      // maxWidth='md'
      open={open}
      {...other}
    >
      <DialogTitle>Usuário</DialogTitle>
      <DialogContent dividers>
        {user && (
          <table>
            <tr>
              <td>
                <strong>Nome:</strong>
              </td>
              <td>{user.name}</td>
            </tr>
            <tr>
              <td>
                <strong>Documento:</strong>
              </td>
              <td>{user.document}</td>
            </tr>
            <tr>
              <td>
                <strong>Email:</strong>
              </td>
              <td>{user.email}</td>
            </tr>
            <tr>
              <td>
                <strong>Telefone:</strong>
              </td>
              <td>{user.phone}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <strong>Localização</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Endereço:</strong>
              </td>
              <td>{user.address?.addressLine1}</td>
            </tr>
            <tr>
              <td>
                <strong>Número:</strong>
              </td>
              <td>{user.address?.number}</td>
            </tr>
            <tr>
              <td>
                <strong>Complemento:</strong>
              </td>
              <td>{user.address?.addressLine2}</td>
            </tr>
            <tr>
              <td>
                <strong>Bairro:</strong>
              </td>
              <td>{user.address?.neighborhood}</td>
            </tr>
            <tr>
              <td>
                <strong>Cidade:</strong>
              </td>
              <td>{user.address?.city}</td>
            </tr>
            <tr>
              <td>
                <strong>Estado:</strong>
              </td>
              <td>{user.address?.state}</td>
            </tr>
            <tr>
              <td>
                <strong>CEP:</strong>
              </td>
              <td>{user.address?.zipCode}</td>
            </tr>
          </table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
