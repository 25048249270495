import React, {memo, useMemo, useState, useCallback, useLayoutEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Chip, Grid, Select, MenuItem, Icon, Checkbox, ListItemIcon, ListItemText } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import BackspaceIcon from '@material-ui/icons/Backspace';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { enUserRoles } from '../entities/models/user';
import { snakeCase } from 'snake-case';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: 20,
    border: '2px solid #ddd',
    borderRadius: 3,
    backgroundColor: "#fafafa",
    maxHeight: 390,
    minHeight: 390,
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    margin: 0,
    padding: 0,
    paddingBottom: 5,
    marginBottom: 10,
    fontWeight: 'bold',
    color: "#215952",
    borderBottom: "1px solid #e9e9f0",
    width: "100%"
  },
  grid: {
    paddingBottom: 20,
    paddingTop: 5
  },
  gridAction: {
    padding: "0px !important",
    "& > *:not(:last-child)": {
      borderRight: "2px solid #ddd"
    }
  },
  actionButton: {
    height: "100%",
    padding: 15
  },
  gridWithTopBorder: {
    borderTop: "2px solid #ddd"
  },
  gridWithoutPaddingBottom: {
    paddingBottom: 0,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '60px',
    minHeight: '60px'
  },
  chip: {
    margin: 2,
  },
  select: {
    maxHeight: '60px',
    minHeight: '60px'
  },
  addTitle: {
    color: "#ddd",
    textAlign: "center"
  },
  addIcon: {
    color: "#ddd",
    fontSize: "7rem"
  },
  addContainer: {
    maxHeight: 390,
    minHeight: 390,
    backgroundColor: "#ffffff !important",
    transition: "all 0.5s 0s",
    filter: "opacity(0.5)",
    "& *": {
      cursor: "pointer",
    },
    "&:hover": {
      transform: "scale(1.3)",
      filter: "opacity(1)",
    }
  },
  addContainerItem: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center"
  }
}));

export default memo(({workflow, services=[], optionsDefault=[], layoutAdd=false, edit=false, onAdd, onEdit, onDelete}) => {
  const classes = useStyles()
  const [servicesSelecteds, setServicesSelecteds] = useState([]);
  const [fromsSelecteds, setFromsSelecteds] = useState([]);
  const [toSelecteds, setToSelecteds] = useState(null);


  const onChangeServices = useCallback((evt) => {
    const { value } = evt.target;
    setServicesSelecteds(value);
  }, []);

  const onChangeFroms = useCallback((evt) => {
    const { value } = evt.target;
    if (value[value.length - 1] === "all") {
      setFromsSelecteds(Object.keys(enUserRoles));
      return;
    }
    setFromsSelecteds(value);
  }, []);

  const onChangeTo = useCallback((evt) => {
    const { value } = evt.target;
    setToSelecteds(value)
  }, []);


  const onSave = useCallback(async () => {
    if(onAdd){
      await onAdd({
        from: fromsSelecteds,
        to: toSelecteds,
        service: servicesSelecteds,
        _id: workflow && workflow._id,
        default: false
      });
      setServicesSelecteds([])
      setFromsSelecteds([])
      setToSelecteds(null);
    }
    onEdit && onEdit(false);
  },[workflow,onEdit, onAdd, fromsSelecteds, toSelecteds, servicesSelecteds])

  useLayoutEffect(() => {
    if(!workflow) return;
    setToSelecteds(workflow.to._id);
    setFromsSelecteds(workflow.from);
    setServicesSelecteds(workflow && workflow.service.map(a => a._id));
  }, [workflow]);

  const servicesView = useMemo(() => {
    return <Grid container spacing={1} className={classes.grid}>
      {edit && (
        <Grid item xs={12} md={12}>
          <Select
            className={classes.select}
            fullWidth
            multiple
            value={servicesSelecteds}
            onChange={onChangeServices}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => {
                  const service = services.find(a => a._id === value);
                  return <Chip icon={<Icon fontSize={'small'}>{snakeCase(service.icon)}</Icon>} className={classes.chip} key={value} label={service.name} />
                })}
              </div>
            )}
          >
            {
              services.map(service => (
                <MenuItem key={service._id} value={service._id}>
                  <Icon fontSize={'small'}>{snakeCase(service.icon)}</Icon>
                  {service.name}
                </MenuItem>
              ))
            }
          </Select>
        </Grid>
      )}
      {
        !edit && <>
          {workflow && workflow.service.map(service => <Grid item>
            <Chip icon={<Icon fontSize={'small'}>{snakeCase(service.icon)}</Icon>} label={service.name} />
          </Grid>)}
        </>
      }
    </Grid>
  }, [classes, workflow, edit, servicesSelecteds, services, onChangeServices]);

  const fromsView = useMemo(() => {
    return <Grid container spacing={1} className={classes.grid}>
      {edit && (
        <Grid item xs={12} md={12}>
          <Select
            className={classes.select}
            fullWidth
            multiple
            value={fromsSelecteds}
            onChange={onChangeFroms}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip className={classes.chip} key={value} label={enUserRoles[value]} />
                ))}
              </div>
            )}
          >
            <MenuItem value="all">
              <ListItemIcon>
                <Checkbox
                  checked={Object.keys(enUserRoles).length > 0 && fromsSelecteds.length === Object.keys(enUserRoles).length}
                  indeterminate={fromsSelecteds.length > 0 && fromsSelecteds.length < Object.keys(enUserRoles).length}
                />
              </ListItemIcon>
              <ListItemText primary="Selecionar todos" />
            </MenuItem>
            {
              Object.keys(enUserRoles).map(roleId => (
                <MenuItem key={roleId} value={roleId}>
                  {enUserRoles[roleId]}
                </MenuItem>
              ))
            }
          </Select>
        </Grid>
      )}
      {
        !edit && <>
          {workflow && workflow.from.map(from => <Grid item>
            <Chip label={enUserRoles[from]} />
          </Grid>)}
        </>
      }
    </Grid>
  }, [classes, workflow, edit, fromsSelecteds, onChangeFroms]);


  const toView = useMemo(() => {
    return <Grid container alignItems="center" spacing={1} className={classes.grid}>
      <Grid item xs={12} md={12} className={classes.title}>
        <Typography  variant={'p'}>
          Enviar para:
        </Typography>
      </Grid>
      <Grid item xs={12} md={edit?12:9}>
        {edit &&  (
          <Select
            className={classes.select}
            fullWidth
            value={toSelecteds}
            onChange={onChangeTo}
          >
            {
              optionsDefault.map(opt => (
                <MenuItem key={opt._id} value={opt._id}>
                  {opt.code ? <b>[{opt.code}]</b>: ""}
                  {opt.name}
                  {opt.secretariat ? <small>({opt.secretariat.code})</small>: ""}
                </MenuItem>
              ))
            }
          </Select>
        )}
        {!edit && <Chip variant="outlined" color="primary" label={workflow && (workflow.to.name || workflow.to.code ) } />}
      </Grid>
    </Grid>
  }, [classes, workflow, edit, optionsDefault, toSelecteds, onChangeTo]);

  const actionView = useMemo(() => {
    return <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      className={`${classes.grid} ${classes.gridWithTopBorder} ${classes.gridWithoutPaddingBottom} ${classes.gridAction}`}
    >
      {edit && (<>
        <Grid item xs={6} md={6}>
          <Button fullWidth className={classes.actionButton} onClick={() => onEdit(false)} >
            <BackspaceIcon />
            Voltar
          </Button>
        </Grid>
        <Grid item xs={6} md={6}>
          <Button fullWidth className={classes.actionButton} onClick={onSave} color="primary">
            <AddCircleIcon />
            Salvar
          </Button>
        </Grid>
      </>)}
      {!edit && (<>
        <Grid item xs={6} md={6}>
          <Button fullWidth className={classes.actionButton} color="secondary">
            <DeleteIcon />
            Deletar
          </Button>
        </Grid>
        <Grid item xs={6} md={6}>
          <Button fullWidth onClick={onEdit} className={classes.actionButton} color="primary">
            <EditIcon />
            Editar
          </Button>
        </Grid>

      </>)}

    </Grid>
  }, [classes, edit, onSave, onEdit]);


  if(layoutAdd && !edit){
    return (<div className={classes.root} style={{overflowY: "hidden"}}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        spacing={1}
        className={classes.addContainer}
        onClick={onEdit}
      >
        <Grid item xs={12} className={classes.addContainerItem}>
          <AddIcon className={classes.addIcon} />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.addTitle} variant={'h4'}>
            Criar novo fluxo
          </Typography>
        </Grid>
      </Grid>

    </div>);
  }


  return (<div className={classes.root}>
    <div className={classes.container}>
      <Typography className={classes.title} variant={'p'}>
        Se o serviço solicitado for:
      </Typography>
      {servicesView}
      <Typography className={classes.title} variant={'p'}>
        e requisitado por:
      </Typography>
      {fromsView}
      {toView}
    </div>
    {actionView}
  </div>);
});
