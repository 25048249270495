import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useNavigate, useParams } from '@reach/router';
import SecretariatService from '../../services/models/secretariat';
import { Card, InputLabel, MenuItem, Snackbar, Typography, TextField, Select } from '@material-ui/core';
import { Alert } from '../../components/toast';
import UserService from '../../services/models/user';

import { Form } from '../../components/Form';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    padding: theme.spacing(2),
  },
}));

export default () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  const [users, setUsers] = useState([]);
  const [initial, setInitial] = useState({});


  useEffect(() => {
    async function fetchData() {
      if (!users.length) {
        const usersReq = await UserService.list(null, 0, 1000);
        setUsers(usersReq.result.list);
      }
    }

    fetchData();
  }, []);

  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'info',
    handleClose: () => {
      console.log('do nothing');
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      try {
        if (params.id) {
          data._id = params.id;
        }
        const result = await SecretariatService.save(data);
        setToast({
          open: true,
          severity: 'success',
          message: `Secretaria ${params.id ? 'editada' : 'criada'} com sucesso`,
          handleClose: () => {
            setToast({
              open: false,
            });
            navigate('/secretariat');
          },
        });
      } catch (err) {
        alert(err.message);
      }
    },
    [navigate, params.id]
  );

  useEffect(() => {
    if (params.id) {
      SecretariatService.findById(params.id)
        .then((secretary) => {
          setInitial(secretary)
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  }, [params.id]);

  return (
    <>
      <Typography variant={'h5'}>Secretarias</Typography>
      <Grid container className={classes.root}>
        <Grid item md={12} xs={12}>
          <Card elevation={2} className={classes.card}>
            <Form
              initialValues={initial}
              onSubmit={onSubmit}
              render={ ({values, handleSubmit, registerField, resetInitial}) => (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth={true}
                        label="Sigla"
                        {...registerField('code')}
                      />
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth={true}
                        label="Nome"
                        {...registerField('name')}
                      />
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <InputLabel id="secretary-select-label">Secretário</InputLabel>
                      <Select
                        fullWidth={true}
                        labelId="secretary-select-label"
                        label="Secretário"
                        name={'secretary'}
                        {...registerField('secretary')}
                      >
                        {users.map((user, index) => (
                          <MenuItem key={user._id} value={user._id}>
                            {user.document} - {user.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item md={12} xs={12} className={classes.buttons}>
                      <Link to="/secretariat">
                        <Button
                          disabled={false}
                          type="button"
                          variant="contained"
                          color="secondary"
                        >
                          Voltar
                        </Button>
                      </Link>
                      <Button disabled={false} type="submit" variant="contained">
                        Salvar
                      </Button>
                    </Grid>

                    <Snackbar open={toast.open} autoHideDuration={1500} onClose={toast.handleClose}>
                      <Alert onClose={toast.handleClose} severity={toast.severity}>
                        {toast.message}
                      </Alert>
                    </Snackbar>
                  </Grid>
                </form>
              )}
            />


          </Card>
        </Grid>
      </Grid>
    </>
  );
};
