import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from '../../components/dataTableAjax';
import ServiceService from '../../services/models/service';
import { Link } from '@reach/router';
import { Box, Card, Icon, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { green, red, teal } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
  buttonAddService: {
    padding: '4px 60px',
    fontSize: '.7rem',
    fontWeight: 400,
    backgroundColor: '#238075',
  },
  icon: {
    color: '#828988',
  },

  editButton: {
    color: teal[300],
    textTransform: 'capitalize',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  public: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
  private: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
}));

const PublicStatus = ({ isPublic }) => {
  const classes = useStyles();

  const classToUse = clsx({
    [classes.public]: isPublic,
    [classes.private]: !isPublic,
  });

  return <span className={classToUse}>{isPublic ? 'Sim' : 'Não'}</span>;
};

export default () => {
  const classes = useStyles();
  const [removeId, setRemoveId] = useState();

  const onRemove = (id) => () => {
    setRemoveId(id);
  };

  const doRemove = async (id) => {
    setRemoveId(null);
  };

  const COLUMNS = [
    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
      grow: 6,
    },
    {
      name: 'Ícone',
      selector: 'icon',
      sortable: false,
      grow: 2,
      cell: (row, index, column, id) => (
        <>
          <Icon className={classes.icon} fontSize="large">
            {row.icon}
          </Icon>
        </>
      ),
    },
    {
      name: 'Público',
      sortable: false,
      grow: 1,
      cell: (row) => <PublicStatus isPublic={row.public}/>,
    },
    {
      name: 'Ações',
      selector: 'actions',
      sortable: false,
      grow: 1,
      cell: (row) => (
        <Link to={`./edit/${row._id}`} className={classes.editButton}>
          Editar
        </Link>
      ),
    },
  ];

  return (
    <>
      <Typography variant={'h5'}>Serviços</Typography>
      <Box width="100%" display="flex" justifyContent="flex-end" padding="0 20px">
        <Button
          component={Link}
          variant="contained"
          color={'primary'}
          to="./create"
          className={classes.buttonAddService}
        >
          Criar Serviço
        </Button>
      </Box>
      <Grid container className={classes.root}>
        <Grid item md={12} xs={12}>
          <Card elevation={2}>
            <DataTable
              columns={COLUMNS}
              fetch={(...prs) => ServiceService.list(...prs)}
              onRemove={doRemove}
              removeId={removeId}
              removeService={ServiceService}
              actions={[]}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
