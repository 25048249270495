import React from 'react';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { value, onChange } = this.props;

    return <ReactQuill value={value} onChange={onChange} />;
  }
}
export default RichTextEditor;
