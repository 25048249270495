import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { DateTime } from 'luxon';
import { PersonOutline } from '@material-ui/icons';
import { blue, red } from '@material-ui/core/colors';
import { PhotoGrid } from './photoGrid';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTimelineOppositeContent-root': {
      flex: 0
    }
  },
  paper: {
    padding: '6px 16px'
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main
  },
  publicItem: {
    backgroundColor: blue[500]
  },
  privateItem: {
    backgroundColor: red[500]
  }
}));

const historyIcons = {
  OPENING: 'add_circle_outline',
  UPDATE: 'cached',
  MESSAGE: 'forum',
  PUBLIC_STATUS_UPDATE: 'notifications',
  PRIVATE_STATUS_UPDATE: 'notifications_none',
  SERVICE_TYPE_UPDATE: 'emoji_objects',
  SECTOR_TRANSFERED: 'account_tree',
  SECTOR_TRANSFERED_AUTOMATIC: 'account_tree',
  PROVIDER_TRANSFERED: 'business',
  CLOSING: 'highlight_off',
  RATED: 'star_rate',
  REOPENING: 'add_circle_outline'
};

export default function HistoryTimeline({ history }) {
  const classes = useStyles();

  const [attachmentsViewIndex, setAttachmentsViewIndex] = useState(-1);

  return (
    <Timeline align='left' className={classes.root}>
      {history.map((item, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent>
            <Typography variant='body2' color='textSecondary'>
              {DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy')} <br />
              {DateTime.fromISO(item.createdAt).toFormat('HH:mm')}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={item.public ? classes.publicItem : classes.privateItem}>
              {/*<Icon>{historyIcons[item.type]}</Icon>*/}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              {/*<Typography variant="h6" component="h1">*/}
              {/*  Eat*/}
              {/*</Typography>*/}
              <div dangerouslySetInnerHTML={{ __html: item.message }} />
              {item.type !== 'OPENING' && attachmentsViewIndex === index && (
                <PhotoGrid attachments={item.attachments} />
              )}
              <br />
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <PersonOutline fontSize={'small'} /> <strong>{item.user ? item.user.name : "Sistema"}</strong>
                </Grid>
                <Grid item>
                  {item.type !== 'OPENING' && item.attachments && item.attachments.length > 0 && (
                    <div style={{ textAlign: 'right' }}>
                      {item.attachments.length} arquivo{item.attachments.length > 1 ? 's' : ''} anexado{item.attachments.length > 1 ? 's' : ''} <br />
                      <span
                        onClick={() => {
                          setAttachmentsViewIndex(attachmentsViewIndex === index ? -1 : index);
                        }}
                      >
                        {attachmentsViewIndex === index ? 'Ocultar' : 'Ver'} anexo{item.attachments.length > 1 ? 's' : ''}
                      </span>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
