import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from '../../components/dataTableAjax';
import ServiceRequestService from '../../services/models/serviceRequest';
import { Link } from '@reach/router';
import { Box, Card, Icon, Typography } from '@material-ui/core';
import { teal } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },

  editButton: {
    color: teal[300],
    textTransform: 'capitalize',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const ExpansibleText = ({ children }) => {
  const classes = useStyles();
  return <span className={classes.expansibleText}>{children}</span>;
};

export default () => {
  const classes = useStyles();
  const [removeId, setRemoveId] = useState();

  const onRemove = (id) => () => {
    setRemoveId(id);
  };

  const doRemove = async (id) => {
    setRemoveId(null);
  };

  const COLUMNS = [
    {
      name: 'Serviço',
      selector: 'service.name',
      sortable: true,
      cell: (row, index, column, id) => (
        <>
          <Icon fontSize={'small'}>{row.service.icon}</Icon>
          <ExpansibleText>{row.service.name}</ExpansibleText>
        </>
      ),
      grow: 3,
    },
    {
      name: 'Sigla',
      selector: 'code',
      sortable: true,
      grow: 2,
    },
    {
      name: 'Solicitante',
      selector: 'requester.name',
      cell: (row, index, column, id) => <ExpansibleText>{row.requester?.name}</ExpansibleText>,
      sortable: true,
      grow: 2,
    },
    {
      name: 'Empresa',
      selector: 'provider.name',
      sortable: true,
      cell: (row, index, column, id) => <ExpansibleText>{row.provider?.name}</ExpansibleText>,
      grow: 2,
    },
    {
      name: 'Status Interno/Externo',
      selector: 'status.private',
      grow: 2,
      cell: (row) => (
        <ExpansibleText>
          {row.status.private}/{row.status.public}
        </ExpansibleText>
      ),
    },
    {
      name: 'Ações',
      selector: 'actions',
      sortable: false,
      cell: (row, index, column, id) => (
        <Link to={`/request/edit/${row._id}`} className={classes.editButton}>
          Visualizar
        </Link>
      ),
    },
  ];

  return (
    <>
      <Typography variant={'h5'}>Demandas</Typography>
      <Box width="100%" display="flex" justifyContent="flex-end" padding="0 20px">
        <Button
          component={Link}
          variant="contained"
          color={'primary'}
          to="/request/create"
          className={classes.buttonAddService}
        >
          Criar Demanda
        </Button>
      </Box>
      <Grid container className={classes.root}>
        <Grid item md={12} xs={12}>
          <Card elevation={2}>
            <DataTable
              columns={COLUMNS}
              fetch={(...prs) => ServiceRequestService.list(...prs)}
              onRemove={doRemove}
              removeId={removeId}
              removeService={ServiceRequestService}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
