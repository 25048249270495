import React, { useCallback, useEffect, useState } from 'react';
import Appbar from '../../components/appbar';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useNavigate, useParams } from '@reach/router';
// import { Controller, useForm } from 'react-hook-form';
import { Card, InputLabel, MenuItem, Select, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '../../components/toast';
import SectorService from '../../services/models/sector';
import UserService from '../../services/models/user';
import SecretariatService from '../../services/models/secretariat';

import { Form } from '../../components/Form';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    padding: theme.spacing(2),
  },
}));

export default () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const params = useParams();
  const [initial, setInitial] = useState({});
  const [users, setUsers] = useState([]);
  const [secretariats, setSecretariats] = useState([]);

  useEffect(() => {
    async function fetchDataUser() {
      if (!users.length) {
        const usersReq = await UserService.list(null, 0, 1000);
        setUsers(usersReq.result.list);
      }
    }
    async function fetchDataSecretariats() {
      if (!secretariats.length) {
        const secretariatsReq = await SecretariatService.list(null, 0, 1000);
        setSecretariats(secretariatsReq.result.list);
      }
    }
    fetchDataUser();
    fetchDataSecretariats();
  }, []);

  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'info',
    handleClose: () => {
      console.log('do nothing');
    },
  });

  const onSubmit = useCallback(
    async ({ passwordConfirm, ...data }) => {
      try {
        if (params.id) {
          data._id = params.id;
        }
        const result = await SectorService.save(data);
        setToast({
          open: true,
          severity: 'success',
          message: `Setor ${params.id ? 'editado' : 'criado'} com sucesso`,
          handleClose: () => {
            setToast({
              open: false,
            });
            navigate('/sector');
          },
        });
      } catch (err) {
        alert(err.message);
      }
    },
    [navigate, params.id]
  );

  useEffect(() => {
    if (params.id) {
      SectorService.findById(params.id)
        .then((sector) => {
          setInitial(sector)
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  }, [params.id]);


  return (
    <>
      <Typography variant={'h5'}>Setores</Typography>
      <Grid container className={classes.root}>
        <Grid item md={12} xs={12}>
          <Card elevation={2} className={classes.card}>
            <Form
              initialValues={initial}
              onSubmit={onSubmit}
              render={ ({values, handleSubmit, registerField, resetInitial}) => (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item md={1} xs={12}>
                      <TextField
                        fullWidth={true}
                        label="Sigla"
                        {...registerField('code')}
                      />
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <InputLabel id="secretariat-select-label">Secretaria</InputLabel>
                      <Select
                        fullWidth={true}
                        labelId="secretariat-select-label"
                        label="Secretaria"
                        {...registerField('secretariat')}
                      >
                        {secretariats.map((sec) => (
                          <MenuItem key={sec._id} value={sec._id}>
                            {sec.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <TextField
                        fullWidth={true}
                        label="Nome do Setor"
                        {...registerField('name')}
                      />
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <InputLabel id="manager-select-label">Chefe</InputLabel>
                      <Select
                        fullWidth={true}
                        labelId="manager-select-label"
                        label="Chefe"
                        {...registerField('manager')}
                      >
                        {users.map((user, index) => (
                          <MenuItem key={user._id} value={user._id}>
                            {user.document} - {user.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item md={12} xs={12} className={classes.buttons}>
                      <Link to="/sector">
                        <Button

                          type="button"
                          variant="contained"
                          color="secondary"
                        >
                          Voltar
                        </Button>
                      </Link>
                      <Button type="submit" variant="contained">
                        Salvar
                      </Button>
                    </Grid>

                    <Snackbar open={toast.open} autoHideDuration={1500} onClose={toast.handleClose}>
                      <Alert onClose={toast.handleClose} severity={toast.severity}>
                        {toast.message}
                      </Alert>
                    </Snackbar>
                  </Grid>
                </form>
              )}
            />

          </Card>
        </Grid>
      </Grid>
    </>
  );
};
