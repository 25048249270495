import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { EventEmitter } from '../services/emitter';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { SearchOutlined } from '@material-ui/icons';
import { useNavigate } from '@reach/router';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(0),
    },
    title: {
      flexGrow: 1,
      color: "#293432",
      fontSize: '0.75rem'
    },
    titleOmit: {
      display: 'none'
    },
    input: {
      flexGrow: 1,
    },
    inputOmit: {
      display: 'none'
    },
    appBar: {
      background: theme.palette.primary.contrastText,
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main + ' !important',
      },
    },
  };
});

export default function AppBarComponent({ title = '', children }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  const onChangeSearchVal = useCallback((evt) => {
    setSearchVal(evt.target.value)
  },[]);

  const onKeyUp = useCallback((evt) => {
    if(evt.keyCode === 13){
      console.log(searchVal);
      setSearchVal("");
      setIsSearching(false);
      navigate(`/search/${searchVal}`);
    }
  },[searchVal, navigate]);

  const toggleSearch = useCallback(() => {
    setIsSearching(!isSearching)
  },[isSearching]);

  const onShowMenu = useCallback((evt) => {
    EventEmitter.emit('menu-toggle');
    toggleSearch()
  }, [toggleSearch]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  },[]);

  const onLogout = useCallback(() => {
    EventEmitter.emit('logout');
    handleClose();
  },[handleClose])

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            onClick={onShowMenu}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <SearchOutlined />
          </IconButton>
          <Typography variant="h6" className={`${classes.title} ${isSearching && classes.titleOmit}`}>
            {title || "Pesquisa por um protocolo, serviço, etc."}
          </Typography>
          <TextField
            className={`${classes.input} ${!isSearching && classes.inputOmit}`}
            placeholder="Pesquisa por um protocolo, serviço, etc."
            onChange={onChangeSearchVal}
            onKeyUp={onKeyUp}
            value={searchVal}
          />
          <div>
            {children}
            {!children && (
              <IconButton onClick={handleClick} color="inherit" aria-label="menu">
                <AccountCircle />
              </IconButton>
            )}
          </div>
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={onLogout}>Sair</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}
