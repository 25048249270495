import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from '../../components/dataTableAjax';
import CompanyService from '../../services/models/company';
import { Link } from '@reach/router';
import { Box, Card, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
}));

export default () => {
  const classes = useStyles();
  const [removeId, setRemoveId] = useState();

  const onRemove = (id) => () => {
    setRemoveId(id);
  };

  const doRemove = async (id) => {
    setRemoveId(null);
  };

  const COLUMNS = [
    {
      name: 'CNPJ',
      selector: 'document',
      sortable: true,
    },
    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Ações',
      selector: 'actions',
      sortable: false,
      cell: (row, index, column, id) => (
        <>
          <Link to={`./edit/${row._id}`}>
            <Button color={'primary'} size="small" variant="contained">
              Editar
            </Button>
          </Link>
          <div style={{ marginLeft: 10 }}>
            <Button
              color={'secondary'}
              size="small"
              onClick={onRemove(row._id)}
              variant="contained"
            >
              Remover
            </Button>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Typography variant={'h5'}>Empresas</Typography>
      <Box width="100%" display="flex" justifyContent="flex-end" padding="0 20px">
        <Button
          component={Link}
          variant="contained"
          color={'primary'}
          to="./create"
          className={classes.buttonAddService}
        >
          Criar Empresa
        </Button>
      </Box>
      <Grid container className={classes.root}>
        <Grid item md={12} xs={12}>
          <Card elevation={2}>
            <DataTable
              columns={COLUMNS}
              fetch={(...prs) => CompanyService.list(...prs)}
              onRemove={doRemove}
              removeId={removeId}
              removeService={CompanyService}
              actions={[
                <Link to="./create" style={{ marginTop: 'auto', marginRight: '5px' }}>
                  <Button variant="contained" color={'primary'}>
                    Criar Empresa
                  </Button>
                </Link>,
              ]}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
