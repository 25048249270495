import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import UserService from '../../services/models/user';
import { Link, useNavigate, useParams } from '@reach/router';
import { Card, InputLabel, MenuItem, Select, Snackbar, Typography } from '@material-ui/core';
import SecretariatService from '../../services/models/secretariat';
import { enUserRoles } from '../../entities/models/user';
import SectorService from '../../services/models/sector';
import CompanyService from '../../services/models/company';
import { Alert } from '../../components/toast';
import { cpf } from 'cpf-cnpj-validator';
import InputMask from "react-input-mask";


import { Form, useFormValues } from '../../components/Form';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    padding: theme.spacing(2),
  },
}));

export default () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [companies, setCompanies] = useState([]);
  const [secretariats, setSecretariats] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [initial, setInitial] = useState({});
  const {
    formProps, formValues
  } = useFormValues(initial)

  useEffect(() => console.log(formValues), [formValues])

  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'info',
    handleClose: () => {
      console.log('do nothing');
    },
  });

  const onSubmit = useCallback(
    async ({ passwordConfirm, ...data }) => {
      try {
        if (data.password && passwordConfirm !== data.password) {
          alert('Senhas não são iguais!');
          return;
        }

        if(!cpf.isValid(data.document.replace(/\D/gi, ''))){
          alert('CPF inválido');
          return;
        }

        if (params.id) {
          data._id = params.id;
        }
        if (
          ['SECRETARY', 'SECTOR_MANAGER', 'SECTOR_MEMBER', 'SERVICES_REQUESTER'].includes(
            data.role
          ) &&
          data.secretary === ''
        ) {
          alert('A Secretaria é obrigatória para esta função!');
        }
        if (
          ['SECTOR_MANAGER', 'SECTOR_MEMBER', 'SERVICES_REQUESTER'].includes(data.role) &&
          data.sector === ''
        ) {
          alert('O Setor é obrigatório para esta função!');
        }

        if (data.secretariat === '') {
          delete data.secretariat;
        }
        if (data.sector === '') {
          delete data.sector;
        }
        const result = await UserService.save(data);

        setToast({
          open: true,
          severity: 'success',
          message: `Usuário ${params.id ? 'editado' : 'criado'} com sucesso`,
          handleClose: () => {
            setToast({
              open: false,
            });
            navigate('/user');
          },
        });
      } catch (err) {
        setToast({
          open: true,
          severity: 'error',
          message: err.message,
          handleClose: () => {
            setToast({
              open: false,
            });
            navigate('/user');
          },
        });
      }
    },
    [navigate, params.id]
  );

  useEffect(() => {
    async function fetchData() {
      if (formValues.secretariat) {
        const sectorsReq = await SectorService.list(null, 0, 1000, {}, formValues.secretariat);
        await setSectors(sectorsReq.result.list);
        if (sectors.length && !sectors.map((sector) => sector._id).includes(formValues.sector)) {
          setInitial({
            ...initial,
            sector: initial.sector || '',
            companies: initial.companies || []
          })
        }
      }
    }

    fetchData();
  }, [formValues.secretariat, formValues.sector]);


  useEffect(() => {
    async function fetchSecretaries() {
      if (!secretariats.length) {
        const secretariatsReq = await SecretariatService.list(null, 0, 1000);
        setSecretariats(secretariatsReq.result.list);
      }
    }

    async function fetchCompanies() {
      if (!companies.length) {
        const companiesReq = await CompanyService.list(null, 0, 1000);
        setCompanies(companiesReq.result.list);
      }
    }

    if (['SERVICE_PROVIDER'].includes(formValues.role)) {
      fetchCompanies();
    } else if (
      ['SECRETARY', 'SECTOR_MANAGER', 'SECTOR_MEMBER', 'SERVICES_REQUESTER'].includes(formValues.role)
    ) {
      fetchSecretaries();
    }
  }, [companies.length, secretariats.length, formValues.role]);

  useEffect(() => {
    if (params.id) {
      UserService.findById(params.id)
        .then(async (user) => {
          setInitial(user)
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  }, [params.id]);


  return (
    <>
      <Typography variant={'h5'}>Usuários</Typography>
      <Grid container className={classes.root}>
        <Grid item md={12} xs={12}>
          <Card elevation={2} className={classes.card}>
            <Form
              {...formProps}
              initialValues={initial} onSubmit={onSubmit}
              render={({values, handleSubmit, registerField, resetInitial}) => (
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item md={3} lg={2} xs={12}>
                      <InputMask
                        {...registerField('document')}
                        mask="999.999.999-99"
                        maskChar="_"
                      >
                        {() => <TextField label="Documento" name="document"  fullWidth={true}/>}
                      </InputMask>

                    </Grid>
                    <Grid item md={3} lg={3} xs={12}>
                      <TextField
                        fullWidth={true}
                        label="Nome"
                        name="name"
                        {...registerField('name')}
                      />
                    </Grid>
                    <Grid item md={3} lg={3} xs={12}>
                      <TextField
                        fullWidth={true}
                        label="Email"
                        name="email"
                        {...registerField('email')}
                      />
                    </Grid>

                    <Grid item md={3} lg={2} xs={12}>
                      <TextField
                        fullWidth={true}
                        label="Senha"
                        name="password"
                        type="password"
                        {...registerField('password')}
                      />
                    </Grid>

                    <Grid item md={3} lg={2} xs={12}>
                      <TextField
                        fullWidth={true}
                        label="Confirmar Senha"
                        name="passwordConfirm"
                        type="password"
                        {...registerField('passwordConfirm')}
                      />
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <InputLabel id="role-select-label">Função</InputLabel>
                      <Select
                        fullWidth={true}
                        labelId="role-select-label"
                        label="Função"
                        name={'role'}
                        {...registerField('role')}
                      >
                        {Object.keys(enUserRoles).map((role, index) => (
                          <MenuItem key={index} value={role}>
                            {enUserRoles[role]}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item md={3} xs={12} hidden={!['SERVICE_PROVIDER'].includes(formValues.role)}>
                      <InputLabel id="companies-select-label">Empresas</InputLabel>
                      <Select
                        multiple
                        fullWidth={true}
                        labelId="companies-select-label"
                        label="Empresas"
                        name={'companies'}
                        {...registerField('companies', {multiple: true})}
                      >
                        {companies.map((company) => (
                          <MenuItem
                            key={company._id}
                            value={company._id}
                          >{`[${company.document}] ${company.name}`}</MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid
                      item
                      md={3}
                      xs={12}
                      hidden={
                        ![
                          'SECRETARY',
                          'SECTOR_MEMBER',
                          'SECTOR_MANAGER',
                          'SERVICES_REQUESTER',
                        ].includes(formValues.role)
                      }
                    >
                      <InputLabel id="secretariat-select-label">Secretaria</InputLabel>
                      <Select
                        fullWidth={true}
                        labelId="secretariat-select-label"
                        label="Secretaria"
                        name={'secretariat'}
                        {...registerField('secretariat')}
                      >
                        {secretariats.map((sec) => (
                          <MenuItem
                            key={sec._id}
                            value={sec._id}
                          >{`[${sec.code}] ${sec.name}`}</MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid
                      item
                      md={3}
                      xs={12}
                      hidden={
                        !['SECTOR_MEMBER', 'SECTOR_MANAGER', 'SERVICES_REQUESTER'].includes(formValues.role)
                      }
                    >
                      <InputLabel id="sector-select-label">Setor</InputLabel>
                      <Select
                        fullWidth={true}
                        labelId="sector-select-label"
                        label="Setor"
                        name={'sector'}
                        {...registerField('sector')}
                      >
                        {sectors.map((sec) => (
                          <MenuItem key={sec._id} value={sec._id}>
                            {sec.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid item md={12} xs={12} className={classes.buttons}>
                      <Link to="/user">
                        <Button
                          type="button"
                          variant="contained"
                          color="secondary"
                        >
                          Voltar
                        </Button>
                      </Link>
                      <Button type="submit" variant="contained">
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            >

            </Form>

            <Snackbar open={toast.open} autoHideDuration={1500} onClose={toast.handleClose}>
              <Alert onClose={toast.handleClose} severity={toast.severity}>
                {toast.message}
              </Alert>
            </Snackbar>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
