class Environment {
  static get() {
    const HOST = window.location.hostname;
    console.log(HOST);

    if (HOST.includes('localhost')) {
      return 'http://localhost:3100';
    }

    if (HOST.includes('demanda-prefeitura-intermediador-web-homolog.s3')) {
      return 'https://prefeitura-demanda-api.cerconsultoria.com';
    }

    if(HOST.includes('prefeitura-demanda-intermediador.cerconsultoria')){
      return 'https://prefeitura-demanda-api.cerconsultoria.com';
    }

    throw new Error('HOST inválido!');
  }
}

export default Environment;
