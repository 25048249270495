import React, {useState, useEffect, useMemo, useCallback } from 'react';
import useAuthenticate from './useAuthenticate.js';
import { enUserRolesId } from '../entities/models/user';
import SectorService from '../services/models/sector';
import ServiceService from '../services/models/service';
import WorkflowService from '../services/models/workflow';

export default () => {
  const {userLogged} = useAuthenticate();
  const [sectors, setSectors] = useState([]);
  const [services, setServices] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [workflowDefault, setWorkflowDefault] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const optionsDefault = useMemo(() => {
    if(userLogged.role === enUserRolesId.MAYOR){
      const secretariatsIds = new Set();
      const secretariats = [];
      for(let sector of sectors){
        if(!secretariatsIds.has(sector.secretariat._id)){
          secretariatsIds.add(sector.secretariat._id);
          secretariats.push(sector.secretariat);
        }
      }
      return secretariats
    } else if(userLogged.role === enUserRolesId.SECRETARY) {
      return sectors.filter(sector => sector.secretariat._id == userLogged.secretariat._id);
    } else if(userLogged.role === enUserRolesId.ADMIN){
      return sectors.sort((a,b) => {
        if(a.secretariat._id == b.secretariat._id){
          return a.name.localeCompare(b.name)
        } else {
          return a.secretariat.name.localeCompare(b.secretariat.name);
        }
      });
    } else {
      return [];
    }
  },[sectors, userLogged]);

  const onRefresh = useCallback(async () => {
    setIsLoading(true);
    Promise.all([
      SectorService.list(null, 0,-1),
      ServiceService.list(null, 0,-1),
      WorkflowService.list(null, 0, -1)
    ]).then(([sectorsResponse, serviceResponse, workflowResponse]) => {
      setSectors(sectorsResponse.result.list);
      setServices(serviceResponse.result.list);
      const notDefaults = workflowResponse.result.list.filter(a => !a.isDefault)
      const defaultWorkflow = workflowResponse.result.list.find(a => a.isDefault)
      console.log(defaultWorkflow)
      setWorkflows(notDefaults);
      setWorkflowDefault(defaultWorkflow);
      setIsLoading(false);
    })
  },[]);

  useEffect(() => {
    onRefresh && onRefresh();
  }, [onRefresh]);

  return {
    isLoading,
    onRefresh,
    services,
    optionsDefault,
    workflowDefault,
    workflows,
    context: [enUserRolesId.SECRETARY, enUserRolesId.MAJOR].includes(userLogged.role) ?
    userLogged.role :
    null
  }
}
