import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useNavigate, useParams } from '@reach/router';
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Icon,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { Alert } from '../../components/toast';
import ServiceService from '../../services/models/service';
import { snakeCase } from 'snake-case';
import { iconsArray } from '../../components/iconsArray';
import CompanyService from '../../services/models/company';
import { Form } from '../../components/Form';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    padding: theme.spacing(2),
  },
  formWrapper: {},
  buttons: {
    padding: '5px 40px',
  },
  spacer: {
    marginBottom: '30px',
  },
}));

export default () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [initial, setInitial] = useState({});

  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'info',
    handleClose: () => {
      console.log('do nothing');
    },
  });

  const onSubmit = useCallback(
    async ({ passwordConfirm, ...data }) => {
      debugger;
      try {
        if (params.id) {
          data._id = params.id;
        }
        const result = await ServiceService.save(data);
        setToast({
          open: true,
          severity: 'success',
          message: `Serviço ${params.id ? 'editado' : 'criado'} com sucesso`,
          handleClose: () => {
            setToast({
              open: false,
            });
            navigate('/service');
          },
        });
      } catch (err) {
        alert(err.message);
      }
    },
    [navigate, params.id]
  );

  useEffect(() => {
    if (params.id) {
      ServiceService.findById(params.id)
        .then((service) => {
          setInitial(service)
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  }, [params.id]);


  return (
    <Box p="20px">
      <Typography color="primary" variant={'h4'} component="h4">
        Serviços
      </Typography>
      <Box m="10px" />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Box display="flex" alignContent="center" justifyContent="center">
            <Typography color="primary" variant="h5">
              Novo Serviço
            </Typography>
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <Form
            initialValues={initial}
            onSubmit={onSubmit}
            render={ ({values, handleSubmit, registerField, resetInitial}) => (
              <Box
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                className={classes.formWrapper}
                padding="40px"
              >
                <TextField
                  label="Nome do serviço"
                  fullWidth
                  {...registerField('name')}
                  className={classes.spacer}
                  helperText="Insira um título para o serviço a ser criado"
                />

                <TextField
                  fullWidth
                  type="number"
                  label="Média de dias para o finalização"
                  className={classes.spacer}
                  {...registerField('completionAverageDays')}
                />

                <FormControl component="fieldset" className={classes.spacer}>
                  <FormLabel component="legend">Tipo de serviço</FormLabel>
                  <RadioGroup
                    row
                    {...registerField('public')}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary"/>}
                      label="Serviço público"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio color="primary"/>}
                      label={
                        <>
                          Serviço restrito{' '}
                          <Typography component="span" variant="caption">
                            (somente para pessoas públicas)
                          </Typography>
                        </>
                      }
                    />
                  </RadioGroup>
                </FormControl>

                <Box className={classes.spacer}>
                  <InputLabel id="icon-select-label">Ícone</InputLabel>
                  <Select
                    fullWidth={true}
                    labelId="icon-select-label"
                    label="Ícone"
                    {...registerField('icon')}
                    defaultValue={'add_circle'}
                  >
                    {iconsArray.map((icon, index) => (
                      <MenuItem key={index} value={snakeCase(icon)}>
                        <ListItemIcon>
                          <Icon fontSize={'small'}>{snakeCase(icon)}</Icon>
                        </ListItemIcon>
                        <ListItemText>{icon}</ListItemText>
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                  <Button
                    component={Link}
                    to="/service"
                    className={classes.buttons}
                    size="small"
                    variant="contained"
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    className={classes.buttons}
                    size="small"
                    variant="contained"
                    color="primary"
                  >
                    Salvar
                  </Button>
                </Box>
              </Box>
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
