import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Business, Close } from '@material-ui/icons';
import RichTextEditor from '../../../components/textEditor';
import UploadImages from '../../../components/imageField';
import React, { useEffect, useState } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { blue, red } from '@material-ui/core/colors';
import { Checkbox, FormControlLabel, MenuItem, Select, Snackbar } from '@material-ui/core';
import CompanyService from '../../../services/models/company';
import SectorService from '../../../services/models/sector';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { Alert } from '../../../components/toast';
import ServiceRequest from '../../../services/models/serviceRequest';
import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  card: {
    padding: theme.spacing(3)
  },
  headerButton: {
    backgroundColor: lighten(theme.palette.primary.main, 0.8)
  },
  blueButton: {
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: lighten(blue[500], 0.3)
    }
  },
  redButton: {
    backgroundColor: red[500]
  }
}));

export function UpdateHistory({ serviceRequest, onUpdate }) {
  const classes = useStyles();

  const [historyText, setHistoryText] = useState('');
  const [historyImages, setHistoryImages] = useState({
    currentFiles: undefined,
    previewImages: undefined
  });
  const [requestId, setRequestId] = useState(null);
  const [showCompanyTransfer, setShowCompanyTransfer] = useState(false);
  const [showSectorTransfer, setShowSectorTransfer] = useState(false);
  const [requestStatus, setRequestStatus] = useState({
    public: 'Aberta',
    private: 'Aberta'
  });

  const [publicHistory, setPublicHistory] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [sectors, setSectors] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedSector, setSelectedSector] = useState('');
  const [deadline, setDeadline] = useState(new Date());

  useEffect(() => {
    if (serviceRequest) {
      if (serviceRequest.provider?._id) {
        setSelectedCompany(serviceRequest.provider._id);
        setShowCompanyTransfer(true);
      }
      if (serviceRequest.sector?._id) {
        setSelectedSector(serviceRequest.sector._id);
        setShowSectorTransfer(true);
      }
      setRequestId(serviceRequest._id);
      setDeadline(new Date(serviceRequest.deadline));
      setRequestStatus(serviceRequest.status);
    }
  }, [serviceRequest]);

  useEffect(() => {
    async function fetchData() {
      if (!companies.length) {
        const companiesReq = await CompanyService.list(null, 0, 1000);
        setCompanies(companiesReq.result.list);
      }

      if (!sectors.length) {
        const sectorsReq = await SectorService.list(null, 0, 1000, {});
        await setSectors(sectorsReq.result.list);
      }
    }

    fetchData();
  }, [companies.length, sectors.length]);

  function clearInsertion() {
    setShowCompanyTransfer(false);
    setShowSectorTransfer(false);
    setHistoryImages([]);
    setHistoryText('');
    setPublicHistory(false);
    setDeadline(serviceRequest.deadline);
  }

  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'info'
  });

  function submitHistory(status) {
    const div = document.createElement('div');
    div.innerHTML = historyText;
    const cleanText = div.textContent || div.innerText || '';

    if (cleanText.length < 30) {
      setToast({
        open: true,
        severity: 'error',
        message: 'Descreva a alteração com pelo menos 30 caracteres.'
      });
      return;
    }

    const formData = new FormData();
    formData.append('_id', serviceRequest._id);

    formData.append('provider', selectedCompany !== '' ? selectedCompany : -1);
    formData.append('sector', selectedSector !== '' ? selectedSector : -1);
    formData.append('message', historyText);
    formData.append('public', publicHistory);
    formData.append('deadline', deadline.toISOString());
    formData.append('type', 'MESSAGE');
    formData.append('status[private]', status.private);
    formData.append('status[public]', status.public);

    if (historyImages && historyImages.currentFiles) {
      historyImages.currentFiles.forEach((file) => {
        formData.append('attachments', file);
      });
    }

    ServiceRequest.interact(serviceRequest._id, formData)
      .then((result) => {
        setToast({
          open: true,
          severity: 'success',
          message: 'Histórico atualizado com sucesso!'
        });
        clearInsertion();
        onUpdate();
      })
      .catch((err) => {
        setToast({
          open: true,
          severity: 'error',
          message: 'Erro ao atualizar histórico.'
        });
      });
  }

  return (
    <>
      <Grid item md={12} xs={12}>
        <Grid
          item
          md={12}
          xs={12}
          style={{
            padding: '20px 0'
          }}
        >
          <Grid container spacing={2} justifyContent={'space-between'}>
            <Grid item md={3} flexGrow>
              <strong>Inserir Interação na Demanda</strong>
            </Grid>
            <Grid item md={9}>
              <Grid container spacing={2} justifyContent={'flex-end'}>
                <Grid item md={3} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={publicHistory}
                        onChange={(ev) => {
                          setPublicHistory(ev.target.checked);
                        }}
                      />
                    }
                    label={'Alteração Pública?'}
                  />
                </Grid>
                <Grid item md={3} sm={12}>
                  <KeyboardDatePicker
                    margin={'none'}
                    id='date-picker-dialog'
                    label='Alterar Prazo'
                    format='dd/MM/yyyy'
                    value={deadline}
                    onChange={(date) => {
                      setDeadline(date);
                    }}
                    style={{
                      top: -16
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </Grid>
                <Grid item md={3} sm={12}>
                  {!showCompanyTransfer && (<Button
                    fullWidth
                    size={'small'}
                    className={showCompanyTransfer ? classes.blueButton : ''}
                    variant={'contained'}
                    onClick={() => {
                      setShowCompanyTransfer(!showCompanyTransfer);
                      setSelectedCompany(serviceRequest.provider?._id || companies[0]?._id || '');
                    }}
                  >
                    <Business fontSize={'small'} /> Transferir para Empresa
                  </Button>)}
                  {showCompanyTransfer && (
                    <Select
                      fullWidth={true}
                      labelId='companies-select-label'
                      label='Empresas'
                      name={'company'}
                      value={selectedCompany}
                      onChange={(ev) => {
                        if (ev.target.value === '') {
                          setShowCompanyTransfer(false);
                        }
                        setSelectedCompany(ev.target.value || '');
                      }}
                    >
                      <MenuItem
                        value={''}
                      >
                        <Close /> Cancelar Transferência
                      </MenuItem>
                      {companies.map((company) => (
                        <MenuItem
                          key={company._id}
                          value={company._id}
                        >{`[${company.document}] ${company.name}`}</MenuItem>
                      ))}
                    </Select>
                  )}
                </Grid>
                <Grid item md={3} sm={12}>
                  {!showSectorTransfer && (
                    <Button
                      size={'small'}
                      fullWidth
                      className={showSectorTransfer ? classes.blueButton : ''}
                      variant={'contained'}
                      onClick={() => {
                        setShowSectorTransfer(!showSectorTransfer);
                        setSelectedSector(serviceRequest.sector?._id || sectors[0]?._id || '');
                      }}
                    >
                      <AccountTreeIcon fontSize={'small'} /> Transferir para Setor
                    </Button>
                  )}

                  {showSectorTransfer && (
                    <Select
                      fullWidth={true}
                      labelId='sector-select-label'
                      label='Setor'
                      value={selectedSector}
                      onChange={(ev) => {
                        if (ev.target.value === '') {
                          setShowSectorTransfer(false);
                        }
                        setSelectedSector(ev.target.value);
                      }}
                      name={'sector'}
                    >
                      <MenuItem
                        value={''}
                      >
                        <Close /> Cancelar Transferência
                      </MenuItem>
                      {sectors.map((sec) => (
                        <MenuItem key={sec._id} value={sec._id}>
                          {`[${sec.code}] ${sec.name}`}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <RichTextEditor value={historyText} onChange={(value) => setHistoryText(value)} />
        <UploadImages
          images={historyImages}
          onChange={(files) => {
            setHistoryImages(files);
          }}
        />

        <Grid
          container
          spacing={2}
          justifyContent={'space-between'}
          style={{
            padding: '20px 0'
          }}
        >
          <Grid item md={3}>
            <Button
              size={'small'}
              variant={'contained'}
              onClick={clearInsertion}
            >
              Limpar
            </Button>
          </Grid>
          <Grid item md={9}>
            <Grid container spacing={2} justifyContent={'flex-end'}>
              <Grid item>
                <Button
                  disabled={!requestId}
                  size={'small'}
                  variant={'contained'}
                  onClick={() => {
                    submitHistory({
                      public: 'Finalizada',
                      private: 'Cancelada'
                    });
                  }}
                >
                  Salvar e Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!requestId}
                  size={'small'}
                  variant={'contained'}
                  onClick={() => {
                    submitHistory({
                      public: 'Finalizada',
                      private: 'Finalizada'
                    });
                  }}
                >
                  Salvar e Finalizar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!requestId}
                  size={'small'}
                  variant={'contained'}
                  onClick={() => {
                    submitHistory(requestStatus);
                  }}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={toast.open}
        autoHideDuration={1500}
        onClose={() => {
          setToast({
            ...toast,
            open: false
          });
        }}
      >
        <Alert
          onClose={() => {
            setToast({
              ...toast,
              open: false
            });
          }}
          severity={toast.severity}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </>
  );
}
