import React, { useEffect, useState } from 'react';
import { EventEmitter } from '../services/emitter';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from '@reach/router';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import ExploreIcon from '@material-ui/icons/Explore';
import { Typography } from '@material-ui/core';
import { Business, PersonOutline, Receipt } from '@material-ui/icons';
import useAuthenticate from '../hooks/useAuthenticate';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    width: drawerWidth,
  },
  brasao: {
    width: '100%',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '1rem',
    padding: theme.spacing(2),
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.contrastText + ' !important',
    },
  },
  active: {
    background: theme.palette.primary.dark,
  },
}));

export default () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { userLogged } = useAuthenticate();

  const onToggle = () => setOpen(!open);
  const onClose = () => setOpen(false);

  useEffect(() => {
    EventEmitter.on('menu-toggle', onToggle);
    return () => EventEmitter.off('menu-toggle', onToggle);
  }, [open]);

  const MENUS = [
    // {
    //   icon: <DashboardIcon />,
    //   label: 'Dashboard',
    //   link: '/'
    // },
    {
      icon: <Receipt />,
      label: 'Demandas',
      link: '/', //'/service-request'
      allowed: [
        'ADMIN',
        'MAYOR',
        'SECRETARY',
        'SECTOR_MANAGER',
        'SECTOR_MEMBER',
        'SERVICES_REQUESTER',
      ],
    },
    {
      icon: <PersonOutline />,
      label: 'Usuários',
      link: '/user',
      allowed: ['ADMIN', 'MAYOR'],
    },
    {
      icon: <AccountBalanceIcon />,
      label: 'Secretarias',
      link: '/secretariat',
      allowed: ['ADMIN', 'MAYOR'],
    },
    {
      icon: <AccountTreeIcon />,
      label: 'Setores',
      link: '/sector',
      allowed: ['ADMIN', 'MAYOR'],
    },
    {
      icon: <Business />,
      label: 'Empresas',
      link: '/company',
      allowed: ['ADMIN', 'MAYOR', 'SECRETARY', 'SECTOR_MANAGER', 'SECTOR_MEMBER'],
    },
    {
      icon: <EmojiObjectsIcon />,
      label: 'Serviços',
      link: '/service',
      allowed: ['ADMIN', 'MAYOR'],
    },
    {
      icon: <AllInclusiveIcon />,
      label: 'Fluxos de Trabalho',
      link: '/workflow',
      allowed: ['ADMIN', 'MAYOR', 'SECRETARY'],
    },
    {
      icon: <ExploreIcon />,
      label: 'Localidades',
      link: '/locales',
      allowed: ['ADMIN', 'MAYOR'],
    },
  ];

  const [allowedMenus, setAllowedMenus] = useState([]);

  useEffect(() => {
    if (userLogged && !allowedMenus.length) {
      const menus = MENUS.filter(
        (menu) =>
          userLogged.role === 'ADMIN' ||
          (userLogged.role !== 'ADMIN' && (!menu.allowed || menu.allowed.includes(userLogged.role)))
      );
      setAllowedMenus(menus);
    }
  }, [userLogged]);

  function isMenuActive(menu) {
    return menu.link === '/'
      ? location.pathname === '/'
      : location.pathname.indexOf(menu.link) !== -1;
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      anchor={'left'}
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div>
        <Typography className={classes.title}>Prefeitura de Viana</Typography>
      </div>
      <List className={classes.root}>
        {allowedMenus.map((menu, index) => (
          <Link onClick={onClose} key={index} to={menu.link}>
            <ListItem className={isMenuActive(menu) ? classes.active : ''}>
              <ListItemIcon> {menu.icon} </ListItemIcon>
              <ListItemText primary={menu.label} />
            </ListItem>
          </Link>
        ))}
      </List>
    </Drawer>
  );
};
