import React, { useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

export function ConfirmationDialogRaw(props) {
  const {
    onClose,
    children,
    title,
    value: valueProp,
    open,
    onEntering,
    onConfirm,
    cancelText,
    okText,
    ...other
  } = props;

  const handleEntering = () => {
    if (onEntering) {
      onEntering();
    }
  };

  const handleCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleOk = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  const openId = useRef(1);

  useEffect(() => {
    if (!open) {
      // Increment id each time modal closes
      openId.current = openId.current + 1;
    }
  }, [open]);

  return (
    <Dialog
      key={openId.current}
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      {children && <DialogContent dividers>{children}</DialogContent>}
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {cancelText || 'Cancelar'}
        </Button>
        <Button onClick={handleOk}>{okText || 'Ok'}</Button>
      </DialogActions>
    </Dialog>
  );
}
