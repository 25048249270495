export const iconsArray = [
  'AccessAlarm',
  'AccessAlarms',
  'AccessibilityNew',
  'Accessible',
  'AccessibleForward',
  'AccessTime',
  'AccountBalance',
  'AccountBalanceWallet',
  'AccountBox',
  'AccountCircle',
  'AccountTree',
  'AcUnit',
  'Adb',
  'Add',
  'AddAlarm',
  'AddAlert',
  'AddAPhoto',
  'AddBox',
  'AddCircle',
  'AddCircleOutline',
  'AddComment',
  'AddIcCall',
  'AddLocation',
  // 'AddPhotoAlternate',
  // 'AddShoppingCart',
  // 'AddToHomeScreen',
  // 'AddToPhotos',
  // 'AddToQueue',
  // 'Adjust',
  // 'AirlineSeatFlat',
  // 'AirlineSeatFlatAngled',
  // 'AirlineSeatIndividualSuite',
  // 'AirlineSeatLegroomExtra',
  // 'AirlineSeatLegroomNormal',
  // 'AirlineSeatLegroomReduced',
  // 'AirlineSeatReclineExtra',
  // 'AirlineSeatReclineNormal',
  // 'AirplanemodeActive',
  // 'AirplanemodeInactive',
  // 'Airplay',
  // 'AirportShuttle',
  // 'Alarm',
  // 'AlarmAdd',
  // 'AlarmOff',
  // 'AlarmOn',
  // 'Album',
  // 'AllInbox',
  // 'AllInclusive',
  // 'AllOut',
  // 'AlternateEmail',
  // 'AmpStories',
  // 'Android',
  // 'Announcement',
  // 'Apartment',
  // 'Apple',
  // 'Apps',
  // 'Archive',
  // 'ArrowBack',
  // 'ArrowBackIos',
  // 'ArrowDownward',
  // 'ArrowDropDown',
  // 'ArrowDropDownCircle',
  // 'ArrowDropUp',
  // 'ArrowForward',
  // 'ArrowForwardIos',
  // 'ArrowLeft',
  // 'ArrowRight',
  // 'ArrowRightAlt',
  // 'ArrowUpward',
  // 'ArtTrack',
  // 'AspectRatio',
  // 'Assessment',
  // 'Assignment',
  // 'AssignmentInd',
  // 'AssignmentLate',
  // 'AssignmentReturn',
  // 'AssignmentReturned',
  // 'AssignmentTurnedIn',
  // 'Assistant',
  // 'AssistantPhoto',
  // 'Atm',
  // 'AttachFile',
  // 'Attachment',
  // 'AttachMoney',
  // 'Audiotrack',
  // 'Autorenew',
  // 'AvTimer',
  // 'Backspace',
  // 'Backup',
  // 'Ballot',
  // 'BarChart',
  // 'Bathtub',
  // 'BatteryAlert',
  // 'BatteryChargingFull',
  // 'BatteryFull',
  // 'BatteryStd',
  // 'BatteryUnknown',
  // 'BeachAccess',
  // 'Beenhere',
  // 'Block',
  // 'Bluetooth',
  // 'BluetoothAudio',
  // 'BluetoothConnected',
  // 'BluetoothDisabled',
  // 'BluetoothSearching',
  // 'BlurCircular',
  // 'BlurLinear',
  // 'BlurOff',
  // 'BlurOn',
  // 'Book',
  // 'Bookmark',
  // 'BookmarkBorder',
  // 'Bookmarks',
  // 'BorderAll',
  // 'BorderBottom',
  // 'BorderClear',
  // 'BorderColor',
  // 'BorderHorizontal',
  // 'BorderInner',
  // 'BorderLeft',
  // 'BorderOuter',
  // 'BorderRight',
  // 'BorderStyle',
  // 'BorderTop',
  // 'BorderVertical',
  // 'BrandingWatermark',
  // 'BrightnessAuto',
  // 'BrightnessHigh',
  // 'BrightnessLow',
  // 'BrightnessMedium',
  // 'BrokenImage',
  // 'Brush',
  // 'BubbleChart',
  // 'BugReport',
  // 'Build',
  // 'BurstMode',
  // 'Business',
  // 'BusinessCenter',
  // 'Cached',
  // 'Cake',
  // 'CalendarToday',
  // 'CalendarViewDay',
  // 'Call',
  // 'CallEnd',
  // 'CallMade',
  // 'CallMerge',
  // 'CallMissed',
  // 'CallMissedOutgoing',
  // 'CallReceived',
  // 'CallSplit',
  // 'CallToAction',
  // 'Camera',
  // 'CameraAlt',
  // 'CameraEnhance',
  // 'CameraFront',
  // 'CameraRear',
  // 'CameraRoll',
  // 'Cancel',
  // 'CancelPresentation',
  // 'CancelScheduleSend',
  // 'CardGiftcard',
  // 'CardMembership',
  // 'CardTravel',
  // 'Casino',
  // 'Cast',
  // 'CastConnected',
  // 'CastForEducation',
  // 'Category',
  // 'CellWifi',
  // 'CenterFocusStrong',
  // 'CenterFocusWeak',
  // 'ChangeHistory',
  // 'Chat',
  // 'ChatBubble',
  // 'ChatBubbleOutline',
  // 'Check',
  // 'CheckBox',
  // 'CheckBoxOutlineBlank',
  // 'CheckCircle',
  // 'CheckCircleOutline',
  // 'ChevronLeft',
  // 'ChevronRight',
  // 'ChildCare',
  // 'ChildFriendly',
  // 'ChromeReaderMode',
  // 'Class',
  // 'Clear',
  // 'ClearAll',
  // 'Close',
  // 'ClosedCaption',
  // 'Cloud',
  // 'CloudCircle',
  // 'CloudDone',
  // 'CloudDownload',
  // 'CloudOff',
  // 'CloudQueue',
  // 'CloudUpload',
  // 'Code',
  // 'Collections',
  // 'CollectionsBookmark',
  // 'Colorize',
  // 'ColorLens',
  // 'Comment',
  // 'Commute',
  // 'Compare',
  // 'CompareArrows',
  // 'CompassCalibration',
  // 'Computer',
  // 'ConfirmationNumber',
  // 'Contactless',
  // 'ContactMail',
  // 'ContactPhone',
  // 'Contacts',
  // 'ContactSupport',
  // 'ControlCamera',
  // 'ControlPoint',
  // 'ControlPointDuplicate',
  // 'Copyright',
  // 'Create',
  // 'CreateNewFolder',
  // 'CreditCard',
  // 'Crop',
  // 'Crop169',
  // 'Crop32',
  // 'Crop54',
  // 'Crop75',
  // 'CropDin',
  // 'CropFree',
  // 'CropLandscape',
  // 'CropOriginal',
  // 'CropPortrait',
  // 'CropRotate',
  // 'CropSquare',
  // 'Dashboard',
  // 'DataUsage',
  // 'DateRange',
  // 'Deck',
  // 'Dehaze',
  // 'Delete',
  // 'DeleteForever',
  // 'DeleteOutline',
  // 'DeleteSweep',
  // 'DepartureBoard',
  // 'Description',
  // 'DesktopAccessDisabled',
  // 'DesktopMac',
  // 'DesktopWindows',
  // 'Details',
  // 'DeveloperBoard',
  // 'DeveloperMode',
  // 'DeviceHub',
  // 'Devices',
  // 'DevicesOther',
  // 'DeviceUnknown',
  // 'DialerSip',
  // 'Dialpad',
  // 'Directions',
  // 'DirectionsBike',
  // 'DirectionsBoat',
  // 'DirectionsBus',
  // 'DirectionsCar',
  // 'DirectionsRailway',
  // 'DirectionsRun',
  // 'DirectionsSubway',
  // 'DirectionsTransit',
  // 'DirectionsWalk',
  // 'DiscFull',
  // 'Dns',
  // 'Dock',
  // 'Domain',
  // 'DomainDisabled',
  // 'Done',
  // 'DoneAll',
  // 'DoneOutline',
  // 'DonutLarge',
  // 'DonutSmall',
  // 'DoubleArrow',
  // 'Drafts',
  // 'DragHandle',
  // 'DragIndicator',
  // 'DriveEta',
  // 'Duo',
  // 'Dvr',
  // 'DynamicFeed',
  // 'Eco',
  // 'Edit',
  // 'EditAttributes',
  // 'EditLocation',
  // 'Eject',
  // 'Email',
  // 'EmojiEmotions',
  // 'EmojiEvents',
  // 'EmojiFlags',
  // 'EmojiFoodBeverage',
  // 'EmojiNature',
  // 'EmojiObjects',
  // 'EmojiPeople',
  // 'EmojiSymbols',
  // 'EmojiTransportation',
  // 'EnhancedEncryption',
  // 'Equalizer',
  // 'Error',
  // 'ErrorOutline',
  // 'Euro',
  // 'EuroSymbol',
  // 'Event',
  // 'EventAvailable',
  // 'EventBusy',
  // 'EventNote',
  // 'EventSeat',
  // 'EvStation',
  // 'ExitToApp',
  // 'ExpandLess',
  // 'ExpandMore',
  // 'Explicit',
  // 'Explore',
  // 'ExploreOff',
  // 'Exposure',
  // 'ExposureNeg1',
  // 'ExposureNeg2',
  // 'ExposurePlus1',
  // 'ExposurePlus2',
  // 'ExposureZero',
  // 'Extension',
  // 'Face',
  // 'Facebook',
  // 'Fastfood',
  // 'FastForward',
  // 'FastRewind',
  // 'Favorite',
  // 'FavoriteBorder',
  // 'FeaturedPlayList',
  // 'FeaturedVideo',
  // 'Feedback',
  // 'FiberDvr',
  // 'FiberManualRecord',
  // 'FiberNew',
  // 'FiberPin',
  // 'FiberSmartRecord',
  // 'FileCopy',
  // 'Filter',
  // 'Filter1',
  // 'Filter2',
  // 'Filter3',
  // 'Filter4',
  // 'Filter5',
  // 'Filter6',
  // 'Filter7',
  // 'Filter8',
  // 'Filter9',
  // 'Filter9Plus',
  // 'FilterBAndW',
  // 'FilterCenterFocus',
  // 'FilterDrama',
  // 'FilterFrames',
  // 'FilterHdr',
  // 'FilterList',
  // 'FilterNone',
  // 'FilterTiltShift',
  // 'FilterVintage',
  // 'FindInPage',
  // 'FindReplace',
  // 'Fingerprint',
  // 'Fireplace',
  // 'FirstPage',
  // 'FitnessCenter',
  // 'Flag',
  // 'Flare',
  // 'FlashAuto',
  // 'FlashOff',
  // 'FlashOn',
  // 'Flight',
  // 'FlightLand',
  // 'FlightTakeoff',
  // 'Flip',
  // 'FlipCameraAndroid',
  // 'FlipCameraIos',
  // 'FlipToBack',
  // 'FlipToFront',
  // 'Folder',
  // 'FolderOpen',
  // 'FolderShared',
  // 'FolderSpecial',
  // 'FontDownload',
  // 'FormatAlignCenter',
  // 'FormatAlignJustify',
  // 'FormatAlignLeft',
  // 'FormatAlignRight',
  // 'FormatBold',
  // 'FormatClear',
  // 'FormatColorFill',
  // 'FormatColorReset',
  // 'FormatColorText',
  // 'FormatIndentDecrease',
  // 'FormatIndentIncrease',
  // 'FormatItalic',
  // 'FormatLineSpacing',
  // 'FormatListBulleted',
  // 'FormatListNumbered',
  // 'FormatListNumberedRtl',
  // 'FormatPaint',
  // 'FormatQuote',
  // 'FormatShapes',
  // 'FormatSize',
  // 'FormatStrikethrough',
  // 'FormatTextdirectionLToR',
  // 'FormatTextdirectionRToL',
  // 'FormatUnderlined',
  // 'Forum',
  // 'Forward',
  // 'Forward10',
  // 'Forward30',
  // 'Forward5',
  // 'FourK',
  // 'FreeBreakfast',
  // 'Fullscreen',
  // 'FullscreenExit',
  // 'Functions',
  // 'Gamepad',
  // 'Games',
  // 'Gavel',
  // 'Gesture',
  // 'GetApp',
  // 'Gif',
  // 'GitHub',
  // 'GolfCourse',
  // 'GpsFixed',
  // 'GpsNotFixed',
  // 'GpsOff',
  // 'Grade',
  // 'Gradient',
  // 'Grain',
  // 'GraphicEq',
  // 'GridOff',
  // 'GridOn',
  // 'Group',
  // 'GroupAdd',
  // 'GroupWork',
  // 'GTranslate',
  // 'Hd',
  // 'HdrOff',
  // 'HdrOn',
  // 'HdrStrong',
  // 'HdrWeak',
  // 'Headset',
  // 'HeadsetMic',
  // 'Healing',
  // 'Hearing',
  // 'Height',
  // 'Help',
  // 'HelpOutline',
  // 'Highlight',
  // 'HighlightOff',
  // 'HighQuality',
  // 'History',
  // 'Home',
  // 'HomeWork',
  // 'HorizontalSplit',
  // 'Hotel',
  // 'HotTub',
  // 'HourglassEmpty',
  // 'HourglassFull',
  // 'House',
  // 'HowToReg',
  // 'HowToVote',
  // 'Http',
  // 'Https',
  // 'Image',
  // 'ImageAspectRatio',
  // 'ImageSearch',
  // 'ImportantDevices',
  // 'ImportContacts',
  // 'ImportExport',
  // 'Inbox',
  // 'IndeterminateCheckBox',
  // 'Info',
  // 'Input',
  // 'InsertChart',
  // 'InsertComment',
  // 'InsertDriveFile',
  // 'InsertEmoticon',
  // 'InsertInvitation',
  // 'InsertLink',
  // 'InsertPhoto',
  // 'Instagram',
  // 'InvertColors',
  // 'InvertColorsOff',
  // 'Iso',
  // 'Keyboard',
  // 'KeyboardArrowDown',
  // 'KeyboardArrowLeft',
  // 'KeyboardArrowRight',
  // 'KeyboardArrowUp',
  // 'KeyboardBackspace',
  // 'KeyboardCapslock',
  // 'KeyboardHide',
  // 'KeyboardReturn',
  // 'KeyboardTab',
  // 'KeyboardVoice',
  // 'KingBed',
  // 'Kitchen',
  // 'Label',
  // 'LabelImportant',
  // 'LabelOff',
  // 'Landscape',
  // 'Language',
  // 'Laptop',
  // 'LaptopChromebook',
  // 'LaptopMac',
  // 'LaptopWindows',
  // 'LastPage',
  // 'Launch',
  // 'Layers',
  // 'LayersClear',
  // 'LeakAdd',
  // 'LeakRemove',
  // 'Lens',
  // 'LibraryAdd',
  // 'LibraryAddCheck',
  // 'LibraryBooks',
  // 'LibraryMusic',
  // 'LinearScale',
  // 'LineStyle',
  // 'LineWeight',
  // 'Link',
  // 'LinkedCamera',
  // 'LinkedIn',
  // 'LinkOff',
  // 'List',
  // 'ListAlt',
  // 'LiveHelp',
  // 'LiveTv',
  // 'LocalActivity',
  // 'LocalAirport',
  // 'LocalAtm',
  // 'LocalBar',
  // 'LocalCafe',
  // 'LocalCarWash',
  // 'LocalConvenienceStore',
  // 'LocalDining',
  // 'LocalDrink',
  // 'LocalFlorist',
  // 'LocalGasStation',
  // 'LocalGroceryStore',
  // 'LocalHospital',
  // 'LocalHotel',
  // 'LocalLaundryService',
  // 'LocalLibrary',
  // 'LocalMall',
  // 'LocalMovies',
  // 'LocalOffer',
  // 'LocalParking',
  // 'LocalPharmacy',
  // 'LocalPhone',
  // 'LocalPizza',
  // 'LocalPlay',
  // 'LocalPostOffice',
  // 'LocalPrintshop',
  // 'LocalSee',
  // 'LocalShipping',
  // 'LocalTaxi',
  // 'LocationCity',
  // 'LocationDisabled',
  // 'LocationOff',
  // 'LocationOn',
  // 'LocationSearching',
  // 'Lock',
  // 'LockOpen',
  // 'Looks',
  // 'Looks3',
  // 'Looks4',
  // 'Looks5',
  // 'Looks6',
  // 'LooksOne',
  // 'LooksTwo',
  // 'Loop',
  // 'Loupe',
  // 'LowPriority',
  // 'Loyalty',
  // 'Mail',
  // 'MailOutline',
  // 'Map',
  // 'Markunread',
  // 'MarkunreadMailbox',
  // 'Maximize',
  // 'MeetingRoom',
  // 'Memory',
  // 'Menu',
  // 'MenuBook',
  // 'MenuOpen',
  // 'MergeType',
  // 'Message',
  // 'Mic',
  // 'MicNone',
  // 'MicOff',
  // 'Minimize',
  // 'MissedVideoCall',
  // 'Mms',
  // 'MobileFriendly',
  // 'MobileOff',
  // 'MobileScreenShare',
  // 'ModeComment',
  // 'MonetizationOn',
  // 'Money',
  // 'MoneyOff',
  // 'MonochromePhotos',
  // 'Mood',
  // 'MoodBad',
  // 'More',
  // 'MoreHoriz',
  // 'MoreVert',
  // 'Motorcycle',
  // 'Mouse',
  // 'MoveToInbox',
  // 'Movie',
  // 'MovieCreation',
  // 'MovieFilter',
  // 'MultilineChart',
  // 'Museum',
  // 'MusicNote',
  // 'MusicOff',
  // 'MusicVideo',
  // 'MyLocation',
  // 'Nature',
  // 'NaturePeople',
  // 'NavigateBefore',
  // 'NavigateNext',
  // 'Navigation',
  // 'NearMe',
  // 'NetworkCell',
  // 'NetworkCheck',
  // 'NetworkLocked',
  // 'NetworkWifi',
  // 'NewReleases',
  // 'NextWeek',
  // 'Nfc',
  // 'NightsStay',
  // 'NoEncryption',
  // 'NoMeetingRoom',
  // 'NoSim',
  // 'Note',
  // 'NoteAdd',
  // 'Notes',
  // 'NotificationImportant',
  // 'Notifications',
  // 'NotificationsActive',
  // 'NotificationsNone',
  // 'NotificationsOff',
  // 'NotificationsPaused',
  // 'NotInterested',
  // 'NotListedLocation',
  // 'OfflineBolt',
  // 'OfflinePin',
  // 'OndemandVideo',
  // 'Opacity',
  // 'OpenInBrowser',
  // 'OpenInNew',
  // 'OpenWith',
  // 'OutdoorGrill',
  // 'Pages',
  // 'Pageview',
  // 'Palette',
  // 'Panorama',
  // 'PanoramaFishEye',
  // 'PanoramaHorizontal',
  // 'PanoramaVertical',
  // 'PanoramaWideAngle',
  // 'PanTool',
  // 'PartyMode',
  // 'Pause',
  // 'PauseCircleFilled',
  // 'PauseCircleOutline',
  // 'PausePresentation',
  // 'Payment',
  // 'People',
  // 'PeopleAlt',
  // 'PeopleOutline',
  // 'PermCameraMic',
  // 'PermContactCalendar',
  // 'PermDataSetting',
  // 'PermDeviceInformation',
  // 'PermIdentity',
  // 'PermMedia',
  // 'PermPhoneMsg',
  // 'PermScanWifi',
  // 'Person',
  // 'PersonAdd',
  // 'PersonAddDisabled',
  // 'PersonalVideo',
  // 'PersonOutline',
  // 'PersonPin',
  // 'PersonPinCircle',
  // 'Pets',
  // 'Phone',
  // 'PhoneAndroid',
  // 'PhoneBluetoothSpeaker',
  // 'PhoneCallback',
  // 'PhoneDisabled',
  // 'PhoneEnabled',
  // 'PhoneForwarded',
  // 'PhoneInTalk',
  // 'PhoneIphone',
  // 'Phonelink',
  // 'PhonelinkErase',
  // 'PhonelinkLock',
  // 'PhonelinkOff',
  // 'PhonelinkRing',
  // 'PhonelinkSetup',
  // 'PhoneLocked',
  // 'PhoneMissed',
  // 'PhonePaused',
  // 'Photo',
  // 'PhotoAlbum',
  // 'PhotoCamera',
  // 'PhotoFilter',
  // 'PhotoLibrary',
  // 'PhotoSizeSelectActual',
  // 'PhotoSizeSelectLarge',
  // 'PhotoSizeSelectSmall',
  // 'PictureAsPdf',
  // 'PictureInPicture',
  // 'PictureInPictureAlt',
  // 'PieChart',
  // 'PinDrop',
  // 'Pinterest',
  // 'Place',
  // 'PlayArrow',
  // 'PlayCircleFilled',
  // 'PlayCircleFilledWhite',
  // 'PlayCircleOutline',
  // 'PlayForWork',
  // 'PlaylistAdd',
  // 'PlaylistAddCheck',
  // 'PlaylistPlay',
  // 'PlusOne',
  // 'Policy',
  // 'Poll',
  // 'Polymer',
  // 'Pool',
  // 'PortableWifiOff',
  // 'Portrait',
  // 'PostAdd',
  // 'Power',
  // 'PowerInput',
  // 'PowerOff',
  // 'PowerSettingsNew',
  // 'PregnantWoman',
  // 'PresentToAll',
  // 'Print',
  // 'PrintDisabled',
  // 'PriorityHigh',
  // 'Public',
  // 'Publish',
  // 'QueryBuilder',
  // 'QuestionAnswer',
  // 'Queue',
  // 'QueueMusic',
  // 'QueuePlayNext',
  // 'Radio',
  // 'RadioButtonChecked',
  // 'RadioButtonUnchecked',
  // 'RateReview',
  // 'Receipt',
  // 'RecentActors',
  // 'RecordVoiceOver',
  // 'Reddit',
  // 'Redeem',
  // 'Redo',
  // 'Refresh',
  // 'Remove',
  // 'RemoveCircle',
  // 'RemoveCircleOutline',
  // 'RemoveFromQueue',
  // 'RemoveRedEye',
  // 'RemoveShoppingCart',
  // 'Reorder',
  // 'Repeat',
  // 'RepeatOne',
  // 'Replay',
  // 'Replay10',
  // 'Replay30',
  // 'Replay5',
  // 'Reply',
  // 'ReplyAll',
  // 'Report',
  // 'ReportOff',
  // 'ReportProblem',
  // 'Restaurant',
  // 'RestaurantMenu',
  // 'Restore',
  // 'RestoreFromTrash',
  // 'RestorePage',
  // 'RingVolume',
  // 'Room',
  // 'RoomService',
  // 'Rotate90DegreesCcw',
  // 'RotateLeft',
  // 'RotateRight',
  // 'Router',
  // 'Rowing',
  // 'RssFeed',
  // 'RvHookup',
  // 'Satellite',
  // 'Save',
  // 'SaveAlt',
  // 'Scanner',
  // 'ScatterPlot',
  // 'Schedule',
  // 'School',
  // 'Score',
  // 'ScreenLockLandscape',
  // 'ScreenLockPortrait',
  // 'ScreenLockRotation',
  // 'ScreenRotation',
  // 'ScreenShare',
  // 'SdCard',
  // 'SdStorage',
  // 'Search',
  // 'Security',
  // 'SelectAll',
  // 'Send',
  // 'SentimentDissatisfied',
  // 'SentimentSatisfied',
  // 'SentimentSatisfiedAlt',
  // 'SentimentVeryDissatisfied',
  // 'SentimentVerySatisfied',
  // 'Settings',
  // 'SettingsApplications',
  // 'SettingsBackupRestore',
  // 'SettingsBluetooth',
  // 'SettingsBrightness',
  // 'SettingsCell',
  // 'SettingsEthernet',
  // 'SettingsInputAntenna',
  // 'SettingsInputComponent',
  // 'SettingsInputComposite',
  // 'SettingsInputHdmi',
  // 'SettingsInputSvideo',
  // 'SettingsOverscan',
  // 'SettingsPhone',
  // 'SettingsPower',
  // 'SettingsRemote',
  // 'SettingsSystemDaydream',
  // 'SettingsVoice',
  // 'Share',
  // 'Shop',
  // 'ShoppingBasket',
  // 'ShoppingCart',
  // 'ShopTwo',
  // 'ShortText',
  // 'ShowChart',
  // 'Shuffle',
  // 'ShutterSpeed',
  // 'SignalCellularAlt',
  // 'SignalCellularNoSim',
  // 'SignalCellularNull',
  // 'SignalCellularOff',
  // 'SignalWifiOff',
  // 'SimCard',
  // 'SingleBed',
  // 'SkipNext',
  // 'SkipPrevious',
  // 'Slideshow',
  // 'SlowMotionVideo',
  // 'Smartphone',
  // 'SmokeFree',
  // 'SmokingRooms',
  // 'Sms',
  // 'SmsFailed',
  // 'Snooze',
  // 'Sort',
  // 'SortByAlpha',
  // 'Spa',
  // 'SpaceBar',
  // 'Speaker',
  // 'SpeakerGroup',
  // 'SpeakerNotes',
  // 'SpeakerNotesOff',
  // 'SpeakerPhone',
  // 'Speed',
  // 'Spellcheck',
  // 'Sports',
  // 'SportsBaseball',
  // 'SportsBasketball',
  // 'SportsCricket',
  // 'SportsEsports',
  // 'SportsFootball',
  // 'SportsGolf',
  // 'SportsHandball',
  // 'SportsHockey',
  // 'SportsKabaddi',
  // 'SportsMma',
  // 'SportsMotorsports',
  // 'SportsRugby',
  // 'SportsSoccer',
  // 'SportsTennis',
  // 'SportsVolleyball',
  // 'SquareFoot',
  // 'Star',
  // 'StarBorder',
  // 'StarHalf',
  // 'StarOutline',
  // 'StarRate',
  // 'Stars',
  // 'StayCurrentLandscape',
  // 'StayCurrentPortrait',
  // 'StayPrimaryLandscape',
  // 'StayPrimaryPortrait',
  // 'Stop',
  // 'StopScreenShare',
  // 'Storage',
  // 'Store',
  // 'Storefront',
  // 'StoreMallDirectory',
  // 'Straighten',
  // 'Streetview',
  // 'StrikethroughS',
  // 'Style',
  // 'SubdirectoryArrowLeft',
  // 'SubdirectoryArrowRight',
  // 'Subject',
  // 'Subscriptions',
  // 'Subtitles',
  // 'Subway',
  // 'SupervisedUserCircle',
  // 'SupervisorAccount',
  // 'SurroundSound',
  // 'SwapCalls',
  // 'SwapHoriz',
  // 'SwapHorizontalCircle',
  // 'SwapVert',
  // 'SwapVerticalCircle',
  // 'SwitchCamera',
  // 'SwitchVideo',
  // 'Sync',
  // 'SyncAlt',
  // 'SyncDisabled',
  // 'SyncProblem',
  // 'SystemUpdate',
  // 'SystemUpdateAlt',
  // 'Tab',
  // 'TableChart',
  // 'Tablet',
  // 'TabletAndroid',
  // 'TabletMac',
  // 'TabUnselected',
  // 'TagFaces',
  // 'TapAndPlay',
  // 'Telegram',
  // 'Terrain',
  // 'TextFields',
  // 'TextFormat',
  // 'TextRotateUp',
  // 'TextRotateVertical',
  // 'TextRotationAngledown',
  // 'TextRotationAngleup',
  // 'TextRotationDown',
  // 'TextRotationNone',
  // 'Textsms',
  // 'Texture',
  // 'Theaters',
  // 'ThreeDRotation',
  // 'ThreeSixty',
  // 'ThumbDown',
  // 'ThumbDownAlt',
  // 'ThumbsUpDown',
  // 'ThumbUp',
  // 'ThumbUpAlt',
  // 'Timelapse',
  // 'Timeline',
  // 'Timer',
  // 'Timer10',
  // 'Timer3',
  // 'TimerOff',
  // 'TimeToLeave',
  // 'Title',
  // 'Toc',
  // 'Today',
  // 'ToggleOff',
  // 'ToggleOn',
  // 'Toll',
  // 'Tonality',
  // 'TouchApp',
  // 'Toys',
  // 'TrackChanges',
  // 'Traffic',
  // 'Train',
  // 'Tram',
  // 'TransferWithinAStation',
  // 'Transform',
  // 'TransitEnterexit',
  // 'Translate',
  // 'TrendingDown',
  // 'TrendingFlat',
  // 'TrendingUp',
  // 'TripOrigin',
  // 'Tune',
  // 'TurnedIn',
  // 'TurnedInNot',
  // 'Tv',
  // 'TvOff',
  // 'Twitter',
  // 'TwoWheeler',
  // 'Unarchive',
  // 'Undo',
  // 'UnfoldLess',
  // 'UnfoldMore',
  // 'Unsubscribe',
  // 'Update',
  // 'Usb',
  // 'VerifiedUser',
  // 'VerticalAlignBottom',
  // 'VerticalAlignCenter',
  // 'VerticalAlignTop',
  // 'VerticalSplit',
  // 'Vibration',
  // 'VideoCall',
  // 'Videocam',
  // 'VideocamOff',
  // 'VideogameAsset',
  // 'VideoLabel',
  // 'VideoLibrary',
  // 'ViewAgenda',
  // 'ViewArray',
  // 'ViewCarousel',
  // 'ViewColumn',
  // 'ViewComfy',
  // 'ViewCompact',
  // 'ViewDay',
  // 'ViewHeadline',
  // 'ViewList',
  // 'ViewModule',
  // 'ViewQuilt',
  // 'ViewStream',
  // 'ViewWeek',
  // 'Vignette',
  // 'Visibility',
  // 'VisibilityOff',
  // 'VoiceChat',
  // 'Voicemail',
  // 'VoiceOverOff',
  // 'VolumeDown',
  // 'VolumeMute',
  // 'VolumeOff',
  // 'VolumeUp',
  // 'VpnKey',
  // 'VpnLock',
  // 'Wallpaper',
  // 'Warning',
  // 'Watch',
  // 'WatchLater',
  // 'Waves',
  // 'WbAuto',
  // 'WbCloudy',
  // 'WbIncandescent',
  // 'WbIridescent',
  // 'WbSunny',
  // 'Wc',
  // 'Web',
  // 'WebAsset',
  // 'Weekend',
  // 'Whatshot',
  // 'WhereToVote',
  // 'Widgets',
  // 'Wifi',
  // 'WifiLock',
  // 'WifiOff',
  // 'WifiTethering',
  // 'Work',
  // 'WorkOff',
  // 'WorkOutline',
  // 'WrapText',
  // 'YoutubeSearchedFor',
  // 'ZoomIn',
  // 'ZoomOut',
  // 'ZoomOutMap'
];
