import React from 'react';
import { makeStyles } from '@material-ui/core';
import Menu from './menu';
import Appbar from './appbar';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  page: {
    background: '#f0f0f7',
    width: '100%',
    '& .MuiTypography-root.MuiTypography-h5': {
      padding: '20px 20px 0',
    },
  },
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

export default function Layout({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* side drawer */}
      <Menu />

      {/* main content */}
      <div className={classes.page}>
        <Appbar />
        {children}
      </div>
    </div>
  );
}
