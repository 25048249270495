import React, { useMemo } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { formatLocationLabel } from '../../../utils/formatLocationLabel';

import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import L from 'leaflet';

const Icon = L.icon({
  iconUrl: "http://simpleicon.com/wp-content/uploads/map-marker-1.svg",
  iconSize: [64, 64],
  iconAnchor: [32, 64],
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null
});

export function LocationDialog(props) {
  const { onClose, open, location, ...other } = props;
  const center = useMemo(() => [location.lat, location.lng], [location]);


  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle>Localização</DialogTitle>
      <DialogContent dividers>
        <div>{formatLocationLabel(location.address)}</div>
        <MapContainer
          center={center}
          zoom={13}
          scrollWheelZoom={false}
          style={{ width: "100%", height: "65vh" }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            icon={Icon}
            draggable
            position={center}
          />
        </MapContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
