import React from 'react';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

export function PhotoDialog(props) {
  const { onClose, open, user, image, ...other } = props;

  return (
    <Dialog maxWidth={'lg'} open={open} {...other}>
      <DialogContent
        dividers
        style={{
          padding: 0,
        }}
      >
        {image && (
          <img
            style={{
              maxWidth: '100%',
            }}
            src={image}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}
