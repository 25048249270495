import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { useNavigate, useParams } from '@reach/router';
import { useForm } from 'react-hook-form';
import { Card, Divider, Icon, Typography } from '@material-ui/core';
import ServiceRequestService from '../../services/models/serviceRequest';
import { Assignment, CalendarToday, LocationOn, PersonOutline } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { UserDialog } from './components/userDialog';
import { LocationDialog } from './components/locationDialog';
import HistoryTimeline from './components/timeline';
import Button from '@material-ui/core/Button';
import { PhotoGrid } from './components/photoGrid';
import { blue, red } from '@material-ui/core/colors';
import { UpdateHistory } from './components/updateHistory';
import { formatLocationLabel } from '../../utils/formatLocationLabel';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    padding: theme.spacing(3),
  },
  headerButton: {
    backgroundColor: lighten(theme.palette.primary.main, 0.8),
  },
  blueButton: {
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: lighten(blue[500], 0.3),
    },
  },
  redButton: {
    backgroundColor: red[500],
  },
}));

export default () => {
  const classes = useStyles();
  const { handleSubmit, register, errors, formState, setValue, getValues, control, trigger } =
    useForm();
  const navigate = useNavigate();
  const params = useParams();

  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'info',
    handleClose: () => {
      setToast((toast) => {
        toast.open = false;
        return toast;
      });
    },
  });

  const onSubmit = useCallback(
    async ({ passwordConfirm, ...data }) => {
      try {
        if (params.id) {
          data._id = params.id;
        }
        const result = await ServiceRequestService.save(data);
        setToast({
          open: true,
          severity: 'success',
          message: `Demanda ${params.id ? 'editada' : 'criada'} com sucesso`,
          handleClose: () => {
            setToast({
              open: false,
            });
            navigate('/request');
          },
        });
      } catch (err) {
        alert(err.message);
      }
    },
    [navigate, params.id]
  );

  const [serviceRequest, setServiceRequest] = useState({
    service: {
      icon: 'location_on',
    },
    requester: {},
    provider: {},
    status: {},
    location: {},
    history: [],
    createdAt: new Date().toISOString(),
  });

  const fetchRequest = useCallback(() => {
    ServiceRequestService.findById(params.id)
      .then((serviceRequest) => {
        for (let key in serviceRequest) {
          setValue(key, serviceRequest[key]);
        }
        setServiceRequest(serviceRequest);
      })
      .catch((err) => {
        alert(err.message);
      });
  }, [params.id, setValue]);

  useEffect(() => {
    if (params.id) {
      fetchRequest();
    }
  }, [fetchRequest, params.id]);

  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [locationrDialogOpen, setLocationDialogOpen] = useState(false);

  return (
    <>
      <Typography variant={'h5'}>Demandas</Typography>
      <Grid container className={classes.root}>
        <Grid item md={12} xs={12}>
          <Card elevation={2} className={classes.card}>
            <Typography
              variant={'h5'}
              style={{
                padding: '20px 0',
              }}
            >
              <Icon>{serviceRequest.service.icon}</Icon> {serviceRequest.service.name} <small>({serviceRequest.code})</small>
            </Typography>

            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item md={2}>
                <Button
                  fullWidth
                  size={'small'}
                  className={classes.headerButton}
                  variant={'contained'}
                  onClick={() => {
                    setUserDialogOpen(true);
                  }}
                >
                  <PersonOutline fontSize={'small'} /> <strong>Criada por:</strong>{' '}
                  {serviceRequest.requester.name}
                </Button>
              </Grid>
              <Grid item md={2}>
                <Button fullWidth size={'small'}>
                  <CalendarToday fontSize={'small'} /> <strong>Criada em:</strong>{' '}
                  {DateTime.fromISO(serviceRequest.createdAt).toFormat("dd/MM/yyyy 'às' HH:mm")}
                </Button>
              </Grid>
              <Grid item md={2}>
                <Button
                  fullWidth
                  size={'small'}
                  className={classes.headerButton}
                  variant={'contained'}
                  onClick={() => {
                    setLocationDialogOpen(true);
                  }}
                >
                  <LocationOn fontSize={'small'} /> <strong>Localização</strong>{' '}
                </Button>
              </Grid>
              <Grid item md={2}>
                <Button fullWidth size={'small'}>
                  <CalendarToday fontSize={'small'} /> <strong>Prazo de Conclusão:</strong>{' '}
                  {DateTime.fromISO(serviceRequest.deadline).toFormat('dd/MM/yyyy')}
                </Button>
              </Grid>
              <Grid item md={2}>
                <Button fullWidth size={'small'}>
                  <Assignment fontSize={'small'} /> Status: {serviceRequest.status.private}
                </Button>
              </Grid>
            </Grid>

            <Divider style={{ marginTop: 30, marginBottom: 30 }} />

            <Grid container spacing={2}>
              <Grid item md={10} xs={12}>
                <strong>Descrição:</strong>
                <br />
                <div dangerouslySetInnerHTML={{ __html: serviceRequest.description }} />
                <PhotoGrid
                  attachments={
                    serviceRequest?.history?.find((h) => h.type === 'OPENING')?.attachments
                  }
                />
              </Grid>
            </Grid>

            <Divider style={{ marginTop: 30, marginBottom: 30 }} />

            <UpdateHistory
              serviceRequest={serviceRequest}
              onUpdate={() => {
                fetchRequest();
              }}
            />

            <Divider style={{ marginTop: 30, marginBottom: 30 }} />

            <Grid item md={12} xs={12}>
              <Typography
                variant={'subtitle1'}
                style={{
                  textAlign: 'center',
                }}
              >
                <strong>Histórico de Atividades</strong>
              </Typography>
              <HistoryTimeline history={serviceRequest.history} />
            </Grid>
            <UserDialog
              user={serviceRequest.requester}
              open={userDialogOpen}
              onClose={() => {
                setUserDialogOpen(false);
              }}
            />
            <LocationDialog
              location={serviceRequest.location}
              open={locationrDialogOpen}
              onClose={() => {
                setLocationDialogOpen(false);
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
