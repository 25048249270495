import React from 'react';
import { Typography } from '@material-ui/core';
import { useParams } from "@reach/router"

export default () => {
  const params = useParams();

  return (
    <>
      <Typography variant={'h5'} style={{color: "#0f4640"}}>
        Procurar por: {params.input}
      </Typography>
    </>
  );
};
