import React, {useCallback, memo, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@reach/router';
import { Card, Typography, Select, MenuItem, Button, CircularProgress } from '@material-ui/core';
import useWorkflow from '../../hooks/useWorkflow';
import WorkflowCard from '../../components/workflowCard';
import WorkflowService from '../../services/models/workflow';
import { enUserRoles } from '../../entities/models/user';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  card: {
    padding: 30
  },
  withBottomBorder: {
    borderBottom: '1px solid #ddd'
  },
  group: {
    backgroundColor: '#eee'
  }
}));


export default memo((props) => {
  const classes = useStyles(props);
  const [isEditingCard, setEditingCard] = useState(null);
  const [editDefault, setEditDefault] = useState(false);
  const {
    workflows,
    workflowDefault,
    optionsDefault,
    context,
    services,
    onRefresh,
    isLoading
  } = useWorkflow();

  console.log(workflowDefault)

  const onSelectDefault = useCallback(async (evt) => {
    await WorkflowService.save({
      from: Object.keys(enUserRoles),
      to: evt.target.value,
      service: services.map(a => a._id),
      default: true
    });
    onRefresh && onRefresh();
  }, [services, onRefresh]);

  const onAdd = useCallback(async (data) => {
    await WorkflowService.save(data);
    onRefresh && onRefresh();
  }, [WorkflowService, onRefresh]);

  const onEdit = useCallback((_id) => {
    return (shouldOpenToEdit) => {

      if(_id == 'editDefault'){
        setEditingCard(null);
        setEditDefault(!!shouldOpenToEdit);
        return;
      }

      if(_id !== isEditingCard){
        setEditingCard(_id)
      } else {
        if(shouldOpenToEdit){
          setEditingCard(_id);
        } else {
          setEditingCard(null);
        }
      }
    }
  }, [isEditingCard]);

  const onDelete = useCallback(() => {

  }, []);

  return (<>
    <Typography variant={'h5'} style={{color: "#0f4640"}}>
      Fluxos de Trabalho
    </Typography>
    <Grid container className={classes.root}>
      <Grid item md={12} xs={12}>
        <Card elevation={2} className={classes.card}>
          <Grid container>
            <Grid item md={2} xs={2}>
              <Typography variant={'h6'} style={{color: "#586361"}}>
                Envio padrão:
              </Typography>
            </Grid>
            <Grid item md={10} xs={10} className={classes.withBottomBorder}>
              <Select
                fullWidth
                value={workflowDefault && workflowDefault.to._id}
                onChange={onSelectDefault}
                style={{color: "#999999"}}
              >
                {
                  optionsDefault.map(opt => (
                    <MenuItem key={opt._id} value={opt._id}>
                      {opt.code ? <b>[{opt.code}]</b>: ""}
                      {opt.name}
                      {opt.secretariat ? <small>({opt.secretariat.code})</small>: ""}
                    </MenuItem>
                  ))
                }
              </Select>
            </Grid>
            <Grid item md={12} xs={12} style={{paddingTop: 30}}>
              <Grid container alignItems="flex-start" alignContent="stretch" spacing={1}>
                <Grid item md={6} xs={6}>
                  <WorkflowCard layoutAdd={true} edit={editDefault} onAdd={onAdd} onEdit={onEdit('editDefault')} onDelete={onDelete} services={services} optionsDefault={optionsDefault} workflow={null}/>
                </Grid>
                {isLoading && (
                  <CircularProgress color="secondary" />
                )}
                {!isLoading && workflows.map(workflow => {
                  return <Grid item md={6} xs={6}>
                    <WorkflowCard onAdd={onAdd} onEdit={onEdit(workflow._id)} onDelete={onDelete} services={services} optionsDefault={optionsDefault} workflow={workflow} edit={isEditingCard == workflow._id}/>
                  </Grid>
                })}
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  </>)
})
