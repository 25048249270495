import React, { useCallback, useEffect, useMemo, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { EventEmitter } from '../services/emitter';

export const LOCALSTORAGE_KEY = 'auth_token';

export const getTokenSync = () => localStorage.getItem(LOCALSTORAGE_KEY);
export const emmitLogout = () => EventEmitter.emit('logout');

export default () => {
  const [token, setToken] = useState(localStorage.getItem(LOCALSTORAGE_KEY));
  // const userLogged = token;
  const userLogged = useMemo(() => token && jwtDecode(token), [token]);

  useEffect(() => {
    setToken(localStorage.getItem(LOCALSTORAGE_KEY));
  }, [localStorage[LOCALSTORAGE_KEY]]);

  const login = useCallback(
    (tokenArg) => {
      try {
        jwtDecode(tokenArg);
        localStorage.setItem(LOCALSTORAGE_KEY, tokenArg);
        setToken(tokenArg);
        EventEmitter.emit('login-change', tokenArg);
      } catch (err) {
        return;
      }
    },
    []
  );

  const logout = useCallback(() => {
    setToken(null);
    localStorage.removeItem(LOCALSTORAGE_KEY);
    EventEmitter.emit('login-change', null);
    window.location.href = '/';
  }, []);

  const onChangeToken = useCallback((newToken) => setToken(newToken), []);

  useEffect(() => {
    EventEmitter.on('login-change', onChangeToken);
    EventEmitter.on('logout', logout);
    return () => {
      EventEmitter.off('login-change', onChangeToken);
      EventEmitter.off('logout', logout);
    };
  }, [logout, onChangeToken]);

  return {
    isLogged: !!token,
    token,
    userLogged,
    login,
    logout,
  };
};
