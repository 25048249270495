import React from 'react';

import SearchList from '../pages/search';

import UsersList from '../pages/user/list';
import UsersCreate from '../pages/user/create';

import SecretariatsList from '../pages/secretariat/list';
import SecretariatCreate from '../pages/secretariat/create';

import SectorsList from '../pages/sector/list';
import SectorCreate from '../pages/sector/create';

import CompaniesList from '../pages/company/list';
import CompanyCreate from '../pages/company/create';

import ServicesList from '../pages/service/list';
import ServiceCreate from '../pages/service/create';

import ServicesRequestsList from '../pages/serviceRequest/list';
import ServicesRequestsView from '../pages/serviceRequest/view';
import ServicesRequestsCreate from '../pages/serviceRequest/create';

import WorkflowsList from '../pages/workflow/index';
import LocalesList from '../pages/locales/index';

import PublicRoutes from './public';
import ProtectedRoutes from './protected';

import { Router } from '@reach/router';
import Layout from '../components/layout';

function Route({ render, ...rest }) {
  return render(rest);
}

const NotFound = () => <div>Sorry, nothing here.</div>;

export default function AppRouter({ children, isLogged }) {
  return (
    <Router>
      <Layout path='/'>
        <ProtectedRoutes isLogged={isLogged} path='/'>
          {/*<Dashboard path='/' />*/}

          <SearchList path='/search/:input' />

          <UsersList path='/user' />
          <UsersCreate path='/user/create' />
          <UsersCreate path='/user/edit/:id' />

          <SecretariatsList path='/secretariat' />
          <SecretariatCreate path='/secretariat/create' />
          <SecretariatCreate path='/secretariat/edit/:id' />

          <SectorsList path='/sector' />
          <SectorCreate path='/sector/create' />
          <SectorCreate path='/sector/edit/:id' />

          <CompaniesList path='/company' />
          <CompanyCreate path='/company/create' />
          <CompanyCreate path='/company/edit/:id' />

          <ServicesList path='/service' />
          <ServiceCreate path='/service/create' />
          <ServiceCreate path='/service/edit/:id' />

          <WorkflowsList path='/workflow' />
          <LocalesList path='/locales' />

          <ServicesRequestsList path='/' />
          <ServicesRequestsList path='/request' />
          <ServicesRequestsCreate path='/request/create' />
          <ServicesRequestsView path='/request/edit/:id' />
        </ProtectedRoutes>

        <PublicRoutes path='/public'>
          <Route path='/' render={() => <h1>Publico</h1>} />
        </PublicRoutes>

        <NotFound default />
      </Layout>
    </Router>
  );
}
