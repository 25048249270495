import AbstractApiService from './abstractApiService';

export default class AuthenticateService extends AbstractApiService {
  static basepath = `/auth`;

  static async login(data) {
    const response = await this.fetch(`${this.basepath}/login`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    return response;
  }
}
