import React, {memo, useState, useCallback, useEffect} from 'react';
import { Grid, Typography, Card, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MapContainer, TileLayer, useMapEvents, Polygon, Popup, Marker } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import LocaleService from '../../services/models/locale';
import { toast } from 'react-toastify';
import L from 'leaflet';


const Icon = L.icon({
  iconUrl: "http://simpleicon.com/wp-content/uploads/map-marker-1.svg",
  iconSize: [64, 64],
  iconAnchor: [32, 64],
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  rootPopup: {
    padding: 20,
    width: 300,
    height: 'auto'
  },
  card: {
    padding: 5
  }
}));


const LocalePopup = memo(({locale, onEdit, onDelete}) => {
  const classes = useStyles()
  return <Popup >
    <Grid className={classes.rootPopup} container justifyContent="space-between" spacing={2}>
      <Grid item xs={12} md={12}>
        <Typography variant={'h6'}>
          {locale.name}
        </Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <Button onClick={onDelete} color="secondary" fullWidth>Deletar</Button>
      </Grid>
      <Grid item xs={6} md={6}>
        <Button onClick={onEdit} color="primary" fullWidth>Editar</Button>
      </Grid>
    </Grid>
  </Popup>
})

const UseEventsMap = memo(({onClick}) => {
  const map = useMapEvents({
    click: function(evt){
      onClick && onClick(evt)
    }
  });
  return (<>

  </>);
})

export default memo(() => {
  const classes = useStyles();
  const [center, setCenter] = useState([-20.381015, -40.454580]);
  const [pointers, setPointers] = useState([]);
  const [isAddingPolygon, setIsAddingPolygon] = useState(false);
  const [color, setColor] = useState(false);
  const [name, setName] = useState("");
  const [selectedLocale, setSelectedLocale] = useState(null);

  const [locales, setLocales] = useState([]);
  console.log(locales)

  const refresh = useCallback(() => {
    LocaleService.list(null, 0, -1).then(result => setLocales(result.result.list))
  }, [])

  useEffect(() => {
    refresh && refresh()
  }, [refresh]);

  const onClikMap = useCallback((evt) => {
    if(isAddingPolygon){
      if(!selectedLocale){
        setPointers([...pointers, evt.latlng]);
      }
    }
  },[pointers, isAddingPolygon, selectedLocale]);

  const resetForm = useCallback(() =>{
    setPointers([]);
    setColor("#000000")
    setName("");
    setSelectedLocale(null);
  },[])

  const onToggleAddPolygon = useCallback(() => {
    setIsAddingPolygon(!isAddingPolygon);
    resetForm()
  }, [isAddingPolygon, resetForm]);

  const onChangeColor= useCallback((evt) => {
    setColor(evt.target.value);
  }, []);

  const onChangeName= useCallback((evt) => {
    setName(evt.target.value);
  }, []);

  const saveLocalidade = useCallback(async () => {
    if(!pointers || !pointers.length || pointers.length < 3){
      return toast('Não é possível adicionar sem pontos');
    }
    await LocaleService.save({name, color, pointers , _id: selectedLocale && selectedLocale._id});
    resetForm();
    setIsAddingPolygon(false);
    refresh()
  }, [refresh, name, color, pointers, resetForm, selectedLocale])

  const onClickEdit = useCallback((locale) => {
    return () => {
      setName(locale.name);
      setColor(locale.color);
      setSelectedLocale(locale);
      setPointers(locale.pointers);
      setIsAddingPolygon(true);
    }
  }, []);

  const onClickDelete = useCallback((locale) => {
    return async () => {
      await LocaleService.remove(locale._id);
      refresh && refresh();
    }
  }, [refresh]);

  const getEvents = useCallback((idxLocale, idx) => {
    return {
      drag: (evt) => {
        locales[idxLocale].pointers[idx].lat = evt.latlng.lat;
        locales[idxLocale].pointers[idx].lng = evt.latlng.lng;
        setSelectedLocale(locales[idxLocale])
        setLocales(locales)
        setPointers(locales[idxLocale].pointers)
      }
    }
  }, [locales])

  return (<>
    <Typography variant={'h5'} style={{color: "#0f4640"}}>
      Localidades liberadas
    </Typography>
    <Grid container spacing={1} className={classes.root}>
      <Grid item md={12} xs={12}>
        <Card elevation={2} className={classes.card}>
          <Grid container spacing={1} justifyContent="flex-end" alignItems="flex-end" alignContent="flex-end" className={classes.root}>
            {
              !isAddingPolygon && <Grid item md={2} xs={12}>
                  <Button onClick={onToggleAddPolygon}>Criar localidade</Button>
              </Grid>

            }
            {
              isAddingPolygon && (<>
                <Grid item md={7} xs={12}>
                  <TextField value={name} onChange={onChangeName} fullWidth type="text" label="Nome" />
                </Grid>
                <Grid item md={1} xs={12}>
                  <TextField value={color} onChange={onChangeColor} fullWidth type="color" label="Cor" />
                </Grid>

                <Grid item md={2} xs={12}>
                  <Button fullWidth onClick={saveLocalidade}>Salvar</Button>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Button fullWidth onClick={onToggleAddPolygon}>Voltar</Button>
                </Grid>
              </>)
            }
            <Grid item md={12} xs={12}>
              <MapContainer
                center={center}
                zoom={13}
                scrollWheelZoom={false}
                style={{ width: "100%", height: "65vh" }}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <UseEventsMap onClick={onClikMap} />
                {pointers && pointers.length && <Polygon pathOptions={{color: color}} positions={pointers} ></Polygon>}
                {
                  locales.map( (locale, idxLocale) => {
                    return <q key={locale._id}>
                      <Polygon pathOptions={{color: locale.color}} positions={locale.pointers} >
                        <LocalePopup onEdit={onClickEdit(locale)} onDelete={onClickDelete(locale)} locale={locale} />
                      </Polygon>
                      {
                        selectedLocale && selectedLocale._id === locale._id && locale.pointers.map( (p, idx) => <Marker
                          icon={Icon}
                          draggable
                          eventHandlers={getEvents(idxLocale, idx)}
                          position={p}
                        />)
                      }
                    </q>
                  })
                }
              </MapContainer>
            </Grid>
          </Grid>
        </Card>
        </Grid>
    </Grid>

  </>)
})
