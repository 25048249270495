import React, { memo, useState, useCallback, useMemo, useEffect } from 'react'


export const useFormValues = (initial, hook) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    setValues({
      ...initial,
      ...values
    })
  }, [JSON.stringify(initial)])

  const onChangeField = useCallback((key, value, options) => {
    setValues({
      ...values,
      [key]: value
    })
    hook && hook(key, value, options)
  }, [values]);

  const getField = useCallback((key) => {
    return values[key]
  }, [values])

  return {
    formProps: {
      onChange: onChangeField
    },
    formValues: values,
    getField
  }
}


const Form = memo(({ initialValues, render, onSubmit, onChange, ...otherProps}) => {
  const [values, setValues] = useState({})
  const [fieldOptions, setFieldOptions] = useState({})

  useEffect(() => {
    if(!initialValues) return
    const valuesInituals = {}
    for(let name in initialValues){
      valuesInituals[name] = {
        error: undefined,
        value: initialValues[name]
      }
    }
    setValues(valuesInituals)
  }, [ initialValues ])

  const handleSubmit = useCallback((evt) => {
    evt.preventDefault();
    onSubmit && onSubmit(Object.keys(values).reduce( (memo, name) => ({...memo, [name]: values[name].value}), {}))
  }, [fieldOptions, values, onSubmit]);

  const onChangeField = useCallback((key, options) => {
    return (evt) => {
      const value = evt.target.value;
      setValues({
        ...values,
        [key]: {
          error: undefined,
          value: value
        }
      })
      if(options){
        setFieldOptions({
          ...fieldOptions,
          [key]: options
        })
      }
      onChange && onChange(key, value, options)
    }
  }, [values, fieldOptions, onChange])

  const registerField = useCallback((name, options) => {
    const props = {
      onChange: onChangeField(name, options),
      value: values[name] ? values[name].value : (options && options.multiple ? [] : "")
    }
    return props
  }, [values])

  const children = useMemo(() => render({
    values,
    handleSubmit,
    registerField,
  }), [values, handleSubmit, render, registerField])


  return (
    <>
      {children}
    </>
  )
})


export {  Form }
