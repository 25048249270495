import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { PhotoGrid } from '../pages/serviceRequest/components/photoGrid';
import { AddAPhoto } from '@material-ui/icons';

export default class UploadImages extends Component {
  constructor(props) {
    super(props);
    this.selectFile = this.selectFile.bind(this);
    this.state = {
      currentFiles: undefined,
      previewImages: undefined,
    };
  }

  selectFile(event) {
    const filesArray = Array.from(event.target.files).slice(0, 3);
    const newState = {
      currentFiles: filesArray,
      previewImages: filesArray.map((file) => URL.createObjectURL(file)),
    };
    this.setState(newState);
    if (this.props.onChange) {
      this.props.onChange(newState);
    }
  }

  render() {
    const { currentFiles, previewImages } = this.state;
    const { onChange, images } = this.props;

    return (
      <div>
        <div
          style={{
            paddingBottom: '20px 0',
          }}
        >
          <label htmlFor="btn-upload">
            <input
              multiple
              id="btn-upload"
              name="btn-upload"
              style={{ display: 'none' }}
              type="file"
              accept="image/*"
              onChange={this.selectFile}
            />
            <Button
              style={{
                width: '100%',
              }}
              size={'small'}
              className="btn-choose"
              variant="outlined"
              component="span"
            >
              <AddAPhoto /> Clique para anexar até 3 imagens
            </Button>
          </label>
        </div>
        {images.previewImages && (
          <PhotoGrid
            removeFunction={(index) => {
              const newFiles = currentFiles;
              const newPreviews = previewImages;
              newFiles.splice(index, 1);
              newPreviews.splice(index, 1);
              const newState = {
                currentFiles: newFiles,
                previewImages: newPreviews,
              };
              this.setState(newState);
              if (onChange) {
                onChange(this.state);
              }
            }}
            attachments={images.previewImages}
          />
        )}
      </div>
    );
  }
}
